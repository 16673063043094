import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { unescapeHtml } from "utils/utils";
import { AlphaNoteChartArea } from "components/AlphaNoteChartArea";
import { AlphaNoteUserInfo } from "components/AlphaNoteUserInfo";
import { useAlphaNoteQuery } from "hooks/queries/useAlphaNoteQuery";
import { useNotice } from "utils/store";
import AlphaNoteMemo from "components/AlphaNoteMemo";
import { useState } from "react";

const HomeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-width: 1128px;
  background: #f9fafc;

  @media (min-width: 1200px) {
    min-width: 1128px;
  }

  @media (max-width: 1200px) {
    min-width: 100%;
    padding: 0 16px;
  }

  .section-bg {
    width: 100%;
    background: #121314;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .fullWidth {
    width: 100%;
    max-width: 1128px;
  }
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 80px;

  @media (max-width: 1200px) {
    padding: 0 0 40px;
  }
`;

const LoadingWrapper = styled.div`
  width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const ErrorWrapper = styled(LoadingWrapper)`
  flex-direction: column;
  gap: 16px;
  color: #ff3d6c;
`;

const AlphaNote = () => {
  const navigate = useNavigate();

  const query = useQuery();
  const cuid = query.get("cuid");
  const date = query.get("date");
  const code = query.get("code");

  if (!cuid || !date || !code) {
    navigate("/");
  }

  const note_id = {
    cuid: cuid,
    date: date,
    code: code,
  };

  const { data, isError, isLoading } = useAlphaNoteQuery({ note_id });

  if (isLoading) {
    return (
      <HomeWrapper>
        <ErrorWrapper>
          {/* <div>데이터를 불러오는데 실패했습니다</div>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded-md"
            onClick={() => window.location.reload()}
          >
            새로고침
          </button> */}
        </ErrorWrapper>
      </HomeWrapper>
    );
  }

  if (isError) {
    return <p>Error loading data</p>;
  }

  const { name } = data?.info || {};

  document.title = `${unescapeHtml(name)} - ${unescapeHtml(
    data?.trader?.nick
  )}`;

  // console.log("data", data);
  return (
    <HomeWrapper>
      <ContentWrapper>
        <AlphaNoteUserInfo
          userName={data?.trader?.nick}
          trader={data?.trader}
          competition={data?.competition}
        />
        <AlphaNoteChartArea data={data} />
        <AlphaNoteMemo cuid={cuid} code={code} />
      </ContentWrapper>
    </HomeWrapper>
  );
};

export default AlphaNote;
