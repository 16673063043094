import BookmarkIcon from "assets/images/ico_bookmark.png";
import { ReactComponent as Complete } from "assets/images/ico_complete.svg";
import { Dot } from "components/AlphaStockTraderCard";
import AlphaStyleTag from "components/AlphaStyleTag";
import NoteLike from "components/Reaction/NoteLike";
import { SvgStkImg } from "components/StockIcon";
import styled from "styled-components";
import { TRADE_STYLE_TYPE } from "utils/consts";
import {
  convertToNearWon,
  convertToPercentage,
  convertToPlusWon,
  formatSeconds,
} from "utils/utils";

const StockItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Pretendard;
  color: #000000;
  height: 31px;
  align-items: center;

  .si-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .si-name {
    margin-left: 8px;
  }

  .si-info {
    display: flex;
    flex-direction: column;
  }

  .si-pnl {
    color: #323233;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.32px;
    text-align: right;
  }

  .si-ret {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.71px;
    text-align: right;
    color: #ff2727;
  }

  .p {
    color: #ff2727;
  }
  .n {
    color: #4c67ff;
  }
`;

const StockItem = ({ code, name, pnl, ret }) => {
  return (
    <StockItemWrapper>
      <div className="si-icon">
        <SvgStkImg code={code} name={name} size={28} />
        <div className="si-name">{name}</div>
      </div>
      <div className="si-info">
        <div className={`si-ret ${ret >= 0 ? "p" : "n"}`}>
          {convertToPercentage(ret)}
        </div>
        <div className="si-pnl">{convertToPlusWon(pnl)}</div>
      </div>
    </StockItemWrapper>
  );
};

const CardWrapper = styled.div`
  box-sizing: border-box;
  width: 267px;
  border-radius: 8px;
  border: 1px solid #ebedf5;
  box-shadow: 5px 5px 22px 0px #6574900f;
  background: #ffffff;
  padding: 16px;
  font-family: Pretendard;
  cursor: pointer;

  .ns-stock-name {
    font-size: 20px;
    font-weight: 600;
    line-height: 23.87px;
    text-align: left;
    color: #000000;
    display: flex;
    gap: 6px;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
    }

    .icons {
      margin-left: auto;
      display: flex;
      gap: 1px;
    }
  }
  .ns-stock-date {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
    text-align: left;
    color: #646566;
    margin-top: 10px;

    svg {
      margin-left: 3px;
    }
  }
  .ns-stock-trx {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
    text-align: left;
    color: #646566;
    display: flex;
    align-items: center;
  }

  .ns-body {
    margin: 16px 0 0 0;
    display: flex;
    flex-direction: column;
    gap: 9px;
  }

  .ns-item {
    display: flex;
    justify-content: space-between;
  }

  .ns-info {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
    text-align: left;
    color: #969799;
  }

  .ns-value {
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.9px;
    text-align: right;
    color: #323233;
  }

  .ns-big {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 500;
    line-height: 23.87px;
    text-align: right;
    color: #000000;
  }

  .ns-extra {
    margin-top: 14px;
    box-sizing: border-box;
    border-radius: 6px;
    background: #f5f7fc;
    display: flex;
    flex-direction: column;

    align-items: center;
  }

  .ns-extra-league {
    box-sizing: border-box;
    width: 100%;
    color: #969799;
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.32px;
    text-align: left;
    padding: 14px 14px 4px 14px;
    display: flex;
    align-items: center;
  }

  .ns-league {
    box-sizing: border-box;

    font-family: Pretendard;
    font-size: 11px;
    font-weight: 700;
    line-height: 13.13px;
    text-align: center;
    color: #828791;
  }

  .ns-extra-trader {
    box-sizing: border-box;
    width: 100%;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.71px;
    text-align: left;
    color: #323233;
    padding: 0 14px 11px;
  }

  .ns-extra-league-trader {
    width: 100%;
    box-sizing: border-box;
    padding: 14px;
    color: #323233;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.51px;
    text-align: left;
    display: flex;
    align-items: center;
  }
  .ns-lt {
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 700;
    line-height: 15.51px;
    text-align: center;
    color: #828791;
  }

  .ns-tag {
    margin: 0 4px 0 0;
  }
`;

export const NOTE_STOCK_TYPE = {
  FULL: "full",
  MID: "mid",
  SIMPLE: "simple",
};

export const NoteStock = ({
  data,
  cardtype = NOTE_STOCK_TYPE.FULL,
  updateIsLiked,
}) => {
  const {
    clear,
    code,
    cuid,
    daterange,
    max_amt_buy,
    n_tr,
    avg_hold_time,
    n_trx,
    name,
    pnl,
    ret,
    style,
    like,
  } = data;

  return (
    <CardWrapper
      key={cuid + code + daterange}
      onClick={() =>
        window.open(
          `/note?cuid=${cuid}&code=${code}&date=${daterange.slice(0, 10)}`,
          "newWindow",
          "width=1300,height=1200"
        )
      }
      className="stock-card"
    >
      <div className="ns-top">
        <div className="ns-stock-info">
          <div className="ns-stock-name">
            <SvgStkImg code={code} name={name} size={22} />
            {name}
            <div className="icons">
              <img style={{ display: "none" }} src={BookmarkIcon}></img>
              <NoteLike
                status={like}
                updateIsLiked={updateIsLiked}
                payload={{
                  cuid: cuid,
                  code: code,
                  date: daterange.slice(0, 10),
                }}
              />
              {/* <img src={LikeIcon}></img> */}
            </div>
          </div>
          <div className="ns-stock-date">
            {daterange}
            {clear && <Complete />}
          </div>
          <div className="ns-stock-trx">
            <AlphaStyleTag className="ns-tag" type={style}>
              {TRADE_STYLE_TYPE[style]}
            </AlphaStyleTag>
            {"보유 " + formatSeconds(avg_hold_time)}
            <Dot color="#AFB0B2" />
            {"체결 " + n_trx + "건"}
          </div>
        </div>
        <div className="ns-like"></div>
      </div>
      <div className="ns-body">
        <div className="ns-item">
          <div className="ns-info ">수익률</div>
          <div className="ns-value ns-big">{convertToPercentage(ret)}</div>
        </div>
        <div className="ns-item">
          <div className="ns-info">수익금</div>
          <div className="ns-value">{convertToNearWon(pnl)}</div>
        </div>
        <div className="ns-item">
          <div className="ns-info">투자금</div>
          <div className="ns-value">{convertToNearWon(max_amt_buy)}</div>
        </div>
      </div>

      {cardtype === NOTE_STOCK_TYPE.FULL && (
        <div className="ns-extra">
          <div className="ns-extra-league">
            <span className="ns-league">1억</span>
            <Dot color="#AFB0B2" />
            <span>{cuid}</span>
          </div>
          <div className="ns-extra-trader">{name}</div>
        </div>
      )}
      {cardtype === NOTE_STOCK_TYPE.MID && (
        <div className="ns-extra">
          <div className="ns-extra-league-trader">
            <span className="ns-lt">{"1억"}</span>
            <Dot color="#AFB0B2" />
            <span>{name}</span>
          </div>
        </div>
      )}
    </CardWrapper>
  );
};
