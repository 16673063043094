import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

const apiURL = "https://api.alphanote.io/api/v1b";

const Container = styled.div`
  max-width: 1128px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, Helvetica, sans-serif;
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
`;

const ErrorMessage = styled.div`
  background-color: #fdecea;
  border: 1px solid #f5c2c7;
  color: #d32f2f;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const SuccessMessage = styled.div`
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  color: #155724;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  margin-bottom: 20px;
`;

const Input = styled.input`
  flex: 1;
  border: 1px solid #ccc;
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const DeleteButton = styled(Button)`
  background-color: #dc3545;

  &:hover {
    background-color: #c82333;
  }
`;

const CopyButton = styled(Button)`
  background-color: #28a745;

  &:hover {
    background-color: #218838;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const Th = styled.th`
  border-bottom: 2px solid #ddd;
  padding: 10px;
  text-align: left;
`;

const Td = styled.td`
  border-bottom: 1px solid #ddd;
  padding: 10px;
`;

const LinkAdminPage = () => {
  const [links, setLinks] = useState([]);
  const [newUrl, setNewUrl] = useState("");
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    fetchLinks();
  }, []);

  const fetchLinks = async () => {
    try {
      const response = await axios.get(`${apiURL}/link/list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setLinks(response.data);
    } catch (err) {
      setError("링크 목록을 불러오는데 실패했습니다.");
      console.error("Error fetching links:", err);
    }
  };

  const addLink = async (e) => {
    e.preventDefault();
    try {
      await axios.get(`${apiURL}/link/add`, {
        params: { url: newUrl },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setNewUrl("");
      fetchLinks();
    } catch (err) {
      setError("링크 추가에 실패했습니다.");
      console.error("Error adding link:", err);
    }
  };

  const deleteLink = async (link) => {
    try {
      await axios.get(`${apiURL}/link/delete?link=${link}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      fetchLinks();
    } catch (err) {
      setError("링크 삭제에 실패했습니다.");
      console.error("Error deleting link:", err);
    }
  };

  const copyToClipboard = (link) => {
    const fullLink = `https://alphanote.io/link/${link}`;
    navigator.clipboard.writeText(fullLink);
    setSuccessMessage(`링크가 클립보드에 복사되었습니다: ${fullLink}`);
    setTimeout(() => setSuccessMessage(null), 3000);
  };

  const getQueryParameters = (url) => {
    const params = new URLSearchParams(url.split("?")[1]);
    return Array.from(params.entries())
      .map(([key, value]) => `${key}=${value}`)
      .join("\n");
  };

  const formatRelativeTime = (date) => dayjs(date).fromNow();

  return (
    <Container>
      <Title>링크 관리</Title>

      {error && <ErrorMessage>{error}</ErrorMessage>}
      {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}

      <Form onSubmit={addLink}>
        <Input
          type="url"
          value={newUrl}
          onChange={(e) => setNewUrl(e.target.value)}
          placeholder="새 URL 입력"
          required
        />
        <Button type="submit">추가</Button>
      </Form>

      <Table>
        <thead>
          <tr>
            <Th>액션</Th>
            <Th>링크</Th>
            <Th>URL</Th>
            <Th>파라미터</Th>
            <Th>생성일</Th>
            <Th>조회수</Th>
            <Th>최근 접속</Th>
            <Th>삭제</Th>
          </tr>
        </thead>
        <tbody>
          {links.map((link, index) => (
            <tr key={index}>
              <Td>
                <CopyButton onClick={() => copyToClipboard(link.link)}>
                  복사
                </CopyButton>
              </Td>
              <Td>{link.link}</Td>
              <Td>{decodeURIComponent(link.url.split("?")[0])}</Td>
              <Td>
                <pre>{getQueryParameters(link.url)}</pre>
              </Td>
              <Td>{link.created_at}</Td>
              <Td>{link.count}</Td>
              <Td>{formatRelativeTime(link.last_access)}</Td>
              <Td>
                <DeleteButton onClick={() => deleteLink(link.link)}>
                  삭제
                </DeleteButton>
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default LinkAdminPage;
