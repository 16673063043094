import DataInfoLine from "components/ToolTip/DataInfoLine";
import { useAnalysisSummaryPerformanceQuery } from "hooks/queries/useMyDetail.js";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";
import { useSelectedCompetition, useNotice } from "utils/store";

import MyDetailDateSection from "pages/Experiment/TestPageDateDetail";
import MyDetailStockSection from "pages/Experiment/TestPageStockDetail";
import DetailSummarySection from "pages/TraderDetail/DetailSummarySection";
import TitleSection from "pages/TraderDetail/TitleSection";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isSafari,
  isChrome,
} from "react-device-detect";
import NoticeModal from "components/Modals/NoticeModal";

const HomeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .section-bg {
    width: 100%;
    background: #121314;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .fullWidth {
    width: 1128px;
  }

  .datainfo {
    padding: 12px 0;
  }
`;

const TestDetail = () => {
  const navigate = useNavigate();
  //   const queryClient = useQueryClient();
  //   const {
  //     data = createMockData(),
  //     isLoading,
  //     isError,
  //   } = useQuery(["tableData", "home"], () => api_axios("get", "home"));

  // const data = createMockData();

  // ...

  const { cuid } = useParams();

  const [type, setType] = useState("home");

  const { selectedCompetition } = useSelectedCompetition((state) => state);

  const { data: summaryPerformanceData } = useAnalysisSummaryPerformanceQuery({
    cid: selectedCompetition?.cid,
    cuid: cuid,
  });

  const { isNoticeShow } = useNotice((state) => state);
  const [isNoticeOpen, setNoticeOpen] = useState(false);

  useEffect(() => {
    if (isMobile && isNoticeShow === false) {
      setNoticeOpen(true);
    }
  }, []);

  // if (isLoading) {
  //   return <p>Loading...</p>;
  // }

  // if (isError) {
  //   return <p>Error loading data</p>;
  // }

  return (
    <HomeWrapper>
      <NoticeModal
        isOpen={isNoticeOpen}
        onCancel={() => {
          setNoticeOpen(false);
        }}
      />

      <div className="section-bg">
        <div className="fullWidth">
          <TitleSection data={summaryPerformanceData || {}} setTab={setType} />
        </div>
      </div>
      <div className="fullWidth">
        <div className="datainfo">
          <DataInfoLine />
        </div>
        {type === "home" && (
          <DetailSummarySection
            summaryPerformanceData={summaryPerformanceData}
          />
        )}
        {type === "detail" && <MyDetailDateSection cuid={cuid} />}
        {type === "notes" && <MyDetailStockSection cuid={cuid} />}
      </div>
      <Tooltip id={"detail-tooltip"} place={"bottom"} />
    </HomeWrapper>
  );
};

export default TestDetail;
