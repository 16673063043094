import { useEffect, useRef } from "react";
import ReactApexChart from "react-apexcharts";

function convertData(data) {
  return data
    ? Object.values(data).map((item) => ({
        name: item.task,
        data: item.period.map((p) => {
          // console.log(
          //   "formatDayTime",
          //   formatDayTime(new Date(period[0]).getTime(), true),
          //   formatDayTime(new Date(period[1]).getTime(), true)
          // );
          return {
            x: item.task,
            //  y: [p[0] * 1000, p[1] * 1000],
            // TBD : fix time zone  issue
            y: [(p[0] + 3600 * 9) * 1000, (p[1] + 3600 * 9) * 1000],
            // y: [new Date(period[0]).getTime(), new Date(period[1]).getTime()],
          };
        }),
      }))
    : [];
}

const options = {
  chart: {
    height: 340,
    type: "rangeBar",
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
    fontFamily: "Pretendard",
    foreColor: "#969799",
    events: {
      dataPointSelection: function (event, chartContext, config) {
        console.log(config);
      },
    },
    background: "#FFFFFF",
    borderRadius: 8,
  },
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: "30%",
      rangeBarGroupRows: true,
      borderRadius: 2,
    },
  },
  xaxis: {
    type: "datetime",
    labels: {
      format: "HH:mm",
      // formatter: function (value, timestamp) {
      //   // console.log(value, timestamp);
      //   const date = new Date(value * 1000); // convert to milliseconds
      //   const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // months are 0-based in JavaScript
      //   const day = date.getUTCDate().toString().padStart(2, "0");
      //   const hours = date.getUTCHours().toString().padStart(2, "0");
      //   const minutes = date.getUTCMinutes().toString().padStart(2, "0");
      //   return `${month}-${day} ${hours}:${minutes}`;
      //   // else return `${hours}:${minutes}`;
      //   return formatDayTime(new Date(value).getTime(), true); // The formatter function overrides format property
      // },

      rotate: -90,
      rotateAlways: true,
      minHeight: 84,
      style: {
        fontSize: "13px",
        fontWeight: 400,
      },
    },
    tickPlacement: "on",
  },
  colors: [
    "#8268EB",
    "#22B0A2",
    "#DA6095",
    "#5BAAF2",
    "#FDD04E",
    "#5D566C",
    "#F4B2D2",
    "#4081FF",
    "#FF7676",
    "#8CC992",
  ],
  grid: {
    borderColor: "#DCDEE3",
    strokeDashArray: 2,
    yaxis: {
      lines: {
        show: true,
      },
    },
    xaxis: {
      lines: {
        show: true,
      },
    },
  },
  tooltip: {
    enabled: false,
  },
  legend: {
    show: false,
  },
};

const MyApexChart = ({ data, width, height = 360, noteIdList = [] }) => {
  const idRef = useRef(noteIdList);
  // const [clickEvent, setClickEvent] = useState(null);
  useEffect(() => {
    idRef.current = noteIdList;
  }, [noteIdList]);

  const ClickEvent = (event, chartContext, config) => {
    if (idRef.current[config?.seriesIndex]) {
      window.open(
        `/note?cuid=${idRef.current[config?.seriesIndex].cuid}&code=${
          idRef.current[config?.seriesIndex].code
        }&date=${idRef.current[config?.seriesIndex].date}`,
        "newwindow",
        "width=1300, height=1200"
      );
    }
  };

  return (
    <ReactApexChart
      options={{
        ...options,
        chart: { ...options.chart, events: { dataPointSelection: ClickEvent } },
      }}
      series={convertData(data)}
      type="rangeBar"
      height={height}
    />
  );
};

export default MyApexChart;
