import { TradingStyleCard } from "components/Card/TradingStyleCard";
import styled from "styled-components";
import {
  convertToNearWon,
  convertToPercentage,
  convertToPnlRatio,
} from "utils/utils";

const CardWrapper = styled.div`
  border: 1px solid #ebedf5;
  background: #ffffff;
  border-radius: 8px;
  width: 100%;

  @media (max-width: 1200px) {
    max-width: 100%;
    overflow: hidden; // 내부 콘텐츠가 넘치지 않도록

    .chart {
      height: 300px; // 모바일에서 차트 높이 조정
      padding: 0 8px; // 좌우 여백 추가
    }
  }

  .p {
    color: #ff2727;
  }

  .n {
    color: #4c67ff;
  }
`;

const StyleWrapper = styled.div`
  margin-top: 60px;

  @media (max-width: 1200px) {
    margin-top: 40px;
  }

  .is-title {
    font-family: Pretendard;
    font-size: 22px;
    font-weight: 600;
    line-height: 26.25px;
    text-align: left;
    color: #141414;

    @media (max-width: 1200px) {
      font-size: 18px;
      line-height: 21px;
    }
  }
  .is-subtitle {
    margin-top: 8px;
    margin-bottom: 20px;
    color: #7d7e80;
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.9px;
    text-align: left;

    @media (max-width: 1200px) {
      font-size: 13px;
      line-height: 15.5px;
      margin-bottom: 16px;
    }
  }
`;

const StyleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 440px;
  gap: 24px;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 16px;

    > div {
      height: auto;
      min-height: 360px;
    }
  }
`;

export const InfoStyleRetSection = ({ data }) => {
  return (
    <StyleWrapper>
      <div className="is-title">스타일 별 승률과 손익비</div>
      <div className="is-subtitle">
        수익이 잘 나는 스타일을 눈으로 직접 확인하고, 해당 스타일에
        집중해보세요.
      </div>
      <StyleGrid>
        <CardWrapper>
          <TradingStyleCard
            data={{
              scalpingData: data.scalping.win_rate,
              dayData: data.day.win_rate,
              swingData: data.swing.win_rate,
            }}
            title="스타일별 승룰"
            value={
              "평균 " +
              convertToPercentage(
                Math.max(
                  data?.scalping?.win_rate,
                  data?.day?.win_rate,
                  data?.swing?.win_rate
                )
              )
            }
            styleValue={""}
            type="bar"
            formatter={(item) => convertToPercentage(item)}
          />
        </CardWrapper>
        <CardWrapper>
          <TradingStyleCard
            width={360}
            data={data}
            title="스타일별 손익비"
            value={
              "평균 " +
              convertToPnlRatio(
                Math.max(
                  data?.scalping?.pnl_ratio,
                  data?.day?.pnl_ratio,
                  data?.swing?.pnl_ratio
                )
              )
            }
            styleValue={""}
            type="pnl_ratio"
          />
        </CardWrapper>
        <CardWrapper>
          <TradingStyleCard
            width={360}
            data={data}
            title="스타일별 수익금"
            value={
              "평균 " +
              convertToNearWon(
                Math.max(data?.scalping?.pnl, data?.day?.pnl, data?.swing?.pnl)
              )
            }
            styleValue={""}
            type="pnl"
            formatter={(item) => convertToNearWon(item)}
          />
        </CardWrapper>
      </StyleGrid>
    </StyleWrapper>
  );
};

export default InfoStyleRetSection;
