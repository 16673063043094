import useHttpClient, { api_v_url } from "hooks/useHttpClient";
import { useQuery } from "react-query";

function useAlphaNoteQuery({ note_id = "" }) {
  const httpClient = useHttpClient(api_v_url);
  return useQuery({
    queryKey: ["note", { note_id }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/note?cuid=${note_id.cuid}&code=${note_id.code}&date=${note_id.date}`
      );
      return queryResult?.data;
    },
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaNoteChartQuery({ note_id = {} }) {
  const httpClient = useHttpClient(api_v_url);
  return useQuery({
    queryKey: ["note-get_min_chart", { note_id }],
    queryFn: async () => {
      try {
        const { data: queryResult } = await httpClient.get(
          `/v1b/note/get_min_chart?cuid=${note_id.cuid}&code=${note_id.code}&date=${note_id.date}&unit=${note_id.unit}`
        );
        return queryResult?.data;
      } catch (error) {
        // HTTP 413 에러 처리
        if (error.response?.status === 413) {
          throw new Error("Too much data");
        }
        // 다른 에러들은 일반적인 에러 메시지로 변환
        throw new Error("Failed to fetch chart data");
      }
    },
    staleTime: 10 * 60 * 60 * 1000,
    retry: (failureCount, error) => {
      // 413 에러(Too much data)인 경우 재시도하지 않음
      if (error.message === "Too much data") {
        return false;
      }
      // 다른 에러는 최대 3번까지 재시도
      return failureCount < 3;
    },
  });
}
export { useAlphaNoteChartQuery, useAlphaNoteQuery };
