import React from "react";
import styled from "styled-components";

const BackgroundWrapper = styled.div`
  background-color: #f0f2f5;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  max-width: 800px;
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  color: #000;
  padding: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  text-align: left;
`;

const Title = styled.h1`
  font-family: Pretendard;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: left;
`;

const Section = styled.section`
  margin-bottom: 30px;
`;

const SectionTitle = styled.h2`
  font-family: Pretendard;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const Paragraph = styled.p`
  font-family: Pretendard;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
`;

const PrivacyPolicyPage = () => {
  const privacyContent = [
    {
      title: "개인정보처리방침",
      content:
        "(주)알파알피에이(이하 '회사')는 이용자의 개인정보보호를 매우 중요시하며, 「개인정보보호법」등 관련 법령을 준수하고 있습니다. 회사는 개인정보처리방침을 통해 이용자의 개인정보가 어떻게 이용되며, 개인정보 보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.\n\n본 개인정보처리방침은 회사의 모든 서비스에 적용되며 다음과 같은 내용을 담고 있습니다.\n\nο 본 방침은 : 2024년 05월 01일부터 시행됩니다.",
    },
    {
      title: "수집하는 개인정보의 항목 및 수집방법",
      content:
        "가. 수집하는 개인정보 항목\n- 회원가입시 (필수): 이메일 주소, 비밀번호\n- 회원가입시 (선택): 휴대폰번호, 초대코드\n- 서비스 이용 과정 자동 수집: IP Address, 쿠키, 서비스 이용 기록, 기기정보\n- 고객 상담 시 (필수): 이름, 이메일 주소, 문의내용\n\n나. 개인정보 수집방법\n- 홈페이지, 모바일 앱, 고객센터를 통한 수집\n- 서비스 이용 과정에서 자동으로 생성되어 수집",
    },
    {
      title: "개인정보의 수집 및 이용목적",
      content:
        "회사는 다음과 같은 목적으로 개인정보를 수집 및 이용합니다:\n- 회원 관리 및 서비스 제공\n- 고객 상담 및 불만 처리\n- 서비스 이용 통계 및 분석\n- 신규 서비스 개발 및 맞춤 서비스 제공\n- 법령 및 회사 규정의 위반 행위 조사",
    },
    {
      title: "개인정보의 보유 및 이용기간",
      content:
        "회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 다만, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.\n\n- 계약 또는 청약철회 등에 관한 기록: 5년 (전자상거래등에서의 소비자보호에 관한 법률)\n- 대금결제 및 재화 등의 공급에 관한 기록: 5년 (전자상거래등에서의 소비자보호에 관한 법률)\n- 소비자의 불만 또는 분쟁처리에 관한 기록: 3년 (전자상거래등에서의 소비자보호에 관한 법률)\n- 로그인 기록: 3개월 (통신비밀보호법)",
    },
    {
      title: "개인정보의 파기절차 및 방법",
      content:
        "회원의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다.\n\n가. 파기절차\n- 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 일정 기간 저장된 후 파기됩니다.\n\n나. 파기방법\n- 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.\n- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.",
    },
    {
      title: "개인정보의 제3자 제공",
      content:
        "회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.\n\n- 이용자들이 사전에 동의한 경우\n- 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우",
    },
    {
      title: "개인정보의 취급위탁",
      content:
        "회사는 현재 개인정보 처리를 외부에 위탁하고 있지 않습니다. 향후 서비스 향상을 위해 개인정보 처리 업무를 위탁하는 경우, 위탁 대상자와 위탁 업무 내용에 대해 이용자에게 통지하고 필요한 경우 사전 동의를 받도록 하겠습니다.",
    },
    {
      title: "이용자 및 법정대리인의 권리와 그 행사방법",
      content:
        "이용자 및 법정대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수 있습니다.\n\n이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 '개인정보변경'(또는 '회원정보수정' 등)을, 가입해지(동의철회)를 위해서는 '회원탈퇴'를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.",
    },
    {
      title: "개인정보 자동수집장치의 설치/운영 및 거부에 관한 사항",
      content:
        "회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 소량의 정보이며 이용자의 PC 컴퓨터의 하드디스크에 저장되기도 합니다.\n\n이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.",
    },
    {
      title: "개인정보의 안전성 확보 조치",
      content:
        "회사는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.\n\n- 개인정보의 암호화\n- 해킹 등에 대비한 기술적 대책\n- 개인정보에 대한 접근 제한\n- 개인정보 취급 직원의 최소화 및 교육",
    },
    {
      title: "개인정보 보호책임자 및 담당부서",
      content:
        "회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.\n\n▶ 개인정보 보호책임자\n성명: 이일규\n직책: CEO\n이메일: lee@arpa.co.kr\n\n▶ 개인정보 보호 담당부서\n부서명: 서비스 운영팀\n담당자: 이일규\n연락처: contact@arpa.co.kr",
    },
    {
      title: "개인정보처리방침 변경",
      content:
        "이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.\n\n공고일자: 2024년 10월 1일\n시행일자: 2024년 10월 1일",
    },
  ];

  return (
    <BackgroundWrapper>
      <ContentWrapper>
        <Title>개인정보 처리방침</Title>

        {privacyContent.map((privacy, index) => (
          <Section key={index}>
            <SectionTitle>{`${index + 1}. ${privacy.title}`}</SectionTitle>
            <Paragraph>{privacy.content}</Paragraph>
          </Section>
        ))}
      </ContentWrapper>
    </BackgroundWrapper>
  );
};

export default PrivacyPolicyPage;
