import { SvgStkImg } from "components/StockIcon";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { convertToPercentage, convertToPlusWon } from "utils/utils";

const StockItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Pretendard;
  color: #000000;
  align-items: center;

  .si-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 0;
  }

  .si-name {
    font-size: 16px;
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .si-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .si-pnl {
    color: #323233;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.32px;
    text-align: right;
  }

  .si-ret {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.71px;
    text-align: right;
  }

  .p {
    color: #ff2727;
  }
  .n {
    color: #4c67ff;
  }
`;

const StockItem = ({ code, name, pnl, ret }) => {
  return (
    <StockItemWrapper>
      <div className="si-icon">
        <SvgStkImg code={code} name={name} size={28} />
        <div className="si-name">{name}</div>
      </div>
      <div className="si-info">
        <div className={`si-ret ${ret >= 0 ? "p" : "n"}`}>
          {convertToPercentage(ret)}
        </div>
        <div className="si-pnl">{convertToPlusWon(pnl)}</div>
      </div>
    </StockItemWrapper>
  );
};

const CardWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  font-family: Pretendard;
  box-sizing: border-box;

  .sl-card-title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    .sl-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.63px;
      color: #141414;
    }

    .sl-date {
      font-size: 12px;
      font-weight: 500;
      line-height: 14.32px;
      color: #969799;
    }
  }

  .sl-value {
    font-size: 28px;
    font-weight: 600;
    line-height: 33.41px;
    text-align: left;
    display: flex;
    gap: 4px;
  }

  .sl-tag {
    padding: 4px 6px;
    margin-left: 4px;
    border-radius: 100px;
    border: 1px solid #c8c9cc;
    color: #969799;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.32px;
    text-align: left;
    white-space: nowrap;

    span {
      margin-left: 2px;
      color: #646566u;
      font-size: 12px;
      font-weight: 600;
      line-height: 14.32px;
    }
  }

  .sl-value-section {
    display: flex;
    align-items: center;
    margin-top: 10px;
    flex-wrap: wrap;
    gap: 8px;
  }

  .sl-tab-wrapper {
    width: 100%;
    display: flex;
    height: 25px;
    font-family: Pretendard;
    margin-top: 20px;
  }

  .sl-tab {
    flex: 1;
    color: #afb0b2;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.71px;
    text-align: center;
    transition: all 0.2s ease;

    &:hover {
      color: #141414;
    }

    &.selected {
      border-bottom: 2px solid #141414;
      color: #141414;
    }
  }

  .sl-tab-table {
    min-height: ${(props) => props.$minHeight}px;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .sl-button {
    cursor: pointer;
    border: 1px solid #c8c9cc;
    border-radius: 6px;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    line-height: 38px;
    transition: all 0.2s ease;

    &:hover {
      background-color: #f5f5f5;
      border-color: #b2b3b5;
    }

    &:active {
      background-color: #ebebeb;
    }
  }

  @media (max-width: 1200px) {
    padding: 0 16px;

    .sl-value {
      font-size: 24px;
      line-height: 28px;
    }

    .sl-tab {
      height: 36px; // 터치 영역 증가
      line-height: 36px; // 터치 영역 증가
    }

    .sl-button {
      height: 44px; // 터치 영역 증가
    }
  }
`;

export const StockListCard = ({
  data,
  slinenum = 6,
  minHeight = 256,
  last_update = "",
}) => {
  const [isSelected, setIsSelected] = useState("total");
  const { complete = [], holding = [], win_rate, n_stock } = data || {};

  const handleTabClick = (tab) => {
    setIsSelected(tab);
  };

  return (
    <CardWrapper $minHeight={minHeight}>
      <div className="sl-card-title-section">
        <div className="sl-title">종목</div>
        <div className="sl-date">{last_update} 기준</div>
      </div>

      <div className="sl-value-section">
        <div className="sl-value">
          <span>{n_stock}종목</span>
        </div>
        <div className="sl-tag">
          종목승률 <span>{convertToPercentage(win_rate)}</span>
        </div>
      </div>

      <div className="sl-tab-wrapper">
        <div
          className={`sl-tab ${isSelected === "total" ? "selected" : ""}`}
          onClick={() => handleTabClick("total")}
        >
          전체 종목
        </div>
        <div
          className={`sl-tab ${isSelected === "hold" ? "selected" : ""}`}
          onClick={() => handleTabClick("hold")}
        >
          보유 종목
        </div>
      </div>
      <div className="sl-tab-table">
        {isSelected === "total" &&
          complete
            .slice(0, slinenum)
            .map((item, index) => (
              <StockItem
                key={index}
                name={item.name}
                code={item.code}
                pnl={item.pnl}
                ret={item.ret}
              />
            ))}
        {isSelected === "hold" &&
          holding
            .slice(0, slinenum)
            .map((item, index) => (
              <StockItem
                key={index}
                name={item.name}
                code={item.code}
                pnl={item.pnl}
                ret={item.ret}
              />
            ))}
      </div>

      <button
        className="sl-button"
        onClick={() =>
          (window.location.href =
            isSelected === "total" ? "#notes" : "#notes?hold=true")
        }
      >
        {isSelected === "total" ? "전체 보기" : "자세히 보기"}
      </button>
    </CardWrapper>
  );
};
