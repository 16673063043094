import { ReactComponent as ArrowLeftIcon } from "assets/images/ico_arrow_left.svg";
import { ReactComponent as ArrowRightIcon } from "assets/images/ico_arrow_right.svg";
import KiumLogo from "assets/images/kium_logo.png";
import MyNoteModal from "components/Modals/MyNoteModal";
import MyNoteUploadModal from "components/Modals/MyNoteUploadModal";
import { StyledDatePickerWrapper } from "components/StyledDatePicker";
import ko from "date-fns/locale/ko";
import {
  useDeleteMyNoteSeedHistory,
  useDeleteMyNoteTradeSnapshot,
  useMyNoteAccountInfo,
  useMyNoteHistoryAttachment,
  useMyNoteSeedHistory,
  useMyNoteTradeSnapshot,
  useUpdateMyNoteAccointInfo,
  useUpdateMyNoteSeedHistory,
} from "hooks/queries/useAlphaTraderDetailQuery";
import moment from "moment";
import React, { useEffect, useRef, useState, useCallback } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { isOpera } from "react-device-detect";
import styled from "styled-components";
import { convertToNearWon } from "utils/utils";

const DetailWrapper = styled.div`
  color: #141414;
  font-family: Pretendard;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #e7e9ef;
  margin-top: 40px;
  margin-bottom: 40px;

  @media (max-width: 1200px) {
    margin-top: 20px 0;
    border-radius: 4px;
  }

  .account-section {
    display: flex;
    border-bottom: 1px solid #ebedf5;
  }

  .account-section,
  .snapshot-section,
  .seed-section,
  .upload-section {
    padding: 32px;

    @media (max-width: 1200px) {
      padding: 16px;
    }
  }

  .account-name {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
    text-align: left;
    color: #969799;

    @media (max-width: 1200px) {
      font-size: 12px;
      line-height: 14px;
    }
  }

  .account-id {
    margin-top: 3px;
    font-size: 20px;
    font-weight: 700;
    line-height: 23.87px;
    text-align: left;
    display: flex;
    align-items: center;

    svg {
      margin-left: 8px;
    }

    @media (max-width: 1200px) {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .edit-icon {
    cursor: pointer;
  }

  .snapshot-section {
    padding: 0 32px;

    @media (max-width: 1200px) {
      padding: 0 16px;
    }
  }

  .seed-section {
    padding: 60px 32px 0;

    @media (max-width: 1200px) {
      padding: 24px 16px 0;
    }
  }

  .upload-section {
    padding: 60px 32px 0;
  }
`;

const EditIcon = ({ fill = "black" }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.87821 4.27746L2.38975 10.7659L2.34339 13.3579L4.9353 13.3115L11.4238 6.82305L8.87821 4.27746ZM9.72674 3.42893L12.2723 5.97452L14.0042 4.24264L11.4586 1.69706L9.72674 3.42893ZM1.12134 14.5799L2.32192 14.5585L5.44121 14.5027L5.44124 14.5027L5.44124 14.5027L14.9942 4.94975C15.3847 4.55922 15.3847 3.92606 14.9942 3.53553L12.1657 0.707107C11.7752 0.316583 11.142 0.316583 10.7515 0.707107L2.0471 9.41152L1.19857 10.26L1.1986 10.2601L1.14281 13.3794L1.12134 14.5799Z"
      fill={fill}
    />
  </svg>
);

const NoteIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 17.5H15C15.2761 17.5 15.5 17.2761 15.5 17V7.25H13.5C11.9812 7.25 10.75 6.01878 10.75 4.5V2.5H5C4.72386 2.5 4.5 2.72386 4.5 3V17C4.5 17.2761 4.72386 17.5 5 17.5ZM12.25 2.87132L15.1287 5.75H13.5C12.8096 5.75 12.25 5.19036 12.25 4.5V2.87132ZM15 19H5C3.89543 19 3 18.1046 3 17V3C3 1.89543 3.89543 1 5 1H11.6716C12.202 1 12.7107 1.21071 13.0858 1.58579L16.4142 4.91421C16.7893 5.28929 17 5.79799 17 6.32843V17C17 18.1046 16.1046 19 15 19ZM7 9.5C6.72386 9.5 6.5 9.72386 6.5 10C6.5 10.2761 6.72386 10.5 7 10.5H13C13.2761 10.5 13.5 10.2761 13.5 10C13.5 9.72386 13.2761 9.5 13 9.5H7ZM6.5 12.5C6.5 12.2239 6.72386 12 7 12H13C13.2761 12 13.5 12.2239 13.5 12.5C13.5 12.7761 13.2761 13 13 13H7C6.72386 13 6.5 12.7761 6.5 12.5ZM7 14.5C6.72386 14.5 6.5 14.7239 6.5 15C6.5 15.2761 6.72386 15.5 7 15.5H13C13.2761 15.5 13.5 15.2761 13.5 15C13.5 14.7239 13.2761 14.5 13 14.5H7Z"
      fill="black"
    />
  </svg>
);

const BarVector = () => (
  <svg
    width="2"
    height="11"
    viewBox="0 0 2 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path opacity="0.1" d="M1 0V10.5" stroke="black" />
  </svg>
);

const YearSectionWrapper = styled.div`
  width: 100%;
  font-family: Pretendard;
  text-align: center;
  color: #000000;
  padding: 40px 0;

  @media (max-width: 1200px) {
    padding: 24px 0;
  }

  .year {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .year-text {
    margin: 0 20px;
    font-size: 30px;
    font-weight: 600;
    line-height: 35.8px;

    @media (max-width: 1200px) {
      font-size: 24px;
      line-height: 28px;
      margin: 0 12px;
    }
  }

  .title {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
    margin-bottom: 2px;
    color: #323233;
  }

  svg {
    cursor: pointer;
  }
`;

const YearSection = ({ year, setYear }) => {
  return (
    <YearSectionWrapper>
      <div className="title">거래 현황표</div>
      <div className="year">
        <ArrowLeftIcon onClick={() => setYear(year - 1)} />
        <div className="year-text">{year}년</div>
        <ArrowRightIcon onClick={() => setYear(year + 1)} />
      </div>
    </YearSectionWrapper>
  );
};

const SectionTitleWrapper = styled.div`
  font-family: Pretendard;
  display: flex;
  justify-content: space-between;
  align-items: end;

  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 21.48px;
    text-align: left;
    display: flex;

    color: #141414;
    display: flex;
  }

  .last-update {
    color: #969799;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
    text-align: left;
    margin-top: 4px;
    margin-left: 7px;
    display: flex;
  }

  .bar {
    margin-right: 7px;
  }

  .note-icon {
    margin-right: 6px;
  }

  .info {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
    text-align: left;
  }
`;
const SectionTitle = ({
  title = "",
  lastUpdated = "",
  description,
  rightChild,
}) => {
  return (
    <SectionTitleWrapper>
      <div>
        <div className="title">
          <div className="note-icon">
            <NoteIcon />
          </div>
          {title}
          {lastUpdated && (
            <div className="last-update">
              <div className="bar">
                <BarVector className="bar" />
              </div>
              {lastUpdated} updated
            </div>
          )}
        </div>
        {description && <div className="info">{description}</div>}
      </div>
      {rightChild}
    </SectionTitleWrapper>
  );
};

const ButtonWrapper = styled.div`
  box-sizing: border-box;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.71px;
  text-align: center;
  color: #ffffff;
  background: #ff3d6c;
  height: 36px;
  padding: 0 10px;
  border-radius: 6px;
  align-content: center;
  cursor: pointer;
  align-self: end;
`;

const CellButtonWrapper = styled.div`
  box-sizing: border-box;
  background: ${(props) => (props.$secondary ? "#ffffff" : "#ff3d6c")};
  color: ${(props) => (props.$secondary ? "#4B4C4D" : "#ffffff")};
  border: ${(props) => (props.$secondary ? "1px solid #E7E9EF" : "none")};
  align-content: center;
  text-align: center;
  width: 60px;
  height: 36px;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  border-radius: 6px;
  cursor: pointer;

  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  @media (max-width: 1200px) {
    font-size: 12px;
    line-height: 14px;
    width: 48px;
    height: 32px;
  }
`;

const MonthlyNoteWarpper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(6, auto);
  gap: 0;
  grid-auto-flow: column;
  border: 1px solid #e7e9ef;
  border-radius: 8px;
  margin: 16px 0 0 0;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border: none;
  }
`;

const MonthlyNoteItem = styled.div`
  font-family: Pretendard;
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
  padding: 32px 24px;
  box-sizing: border-box;
  border: 1px solid #e7e9ef;
  background: ${(props) => (props.empty === "empty" ? "#fafafa" : "#ffffff")};

  @media (max-width: 1200px) {
    height: auto;
    padding: 6px 16px;
    border-radius: 8px;
  }

  &.item-1 {
    border-top-left-radius: 8px;
  }
  &.item-7 {
    border-top-right-radius: 8px;
  }
  &.item-6 {
    border-bottom-left-radius: 8px;
  }
  &.item-12 {
    border-bottom-right-radius: 8px;
  }

  .month {
    font-size: 15px;
    font-weight: 600;
    line-height: 17.9px;

    @media (max-width: 1200px) {
      font-size: 13px;
      line-height: 15px;
      width: 40px;
    }
  }

  .trx_range {
    margin-left: 33px;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.9px;

    @media (max-width: 1200px) {
      margin-left: 12px;
      font-size: 13px;
      line-height: 15px;
      flex: 1;

      // 날짜 표시를 간소화 (예: "03.01 ~ 03.31" 형식)
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .delete {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
    margin-left: auto;
    display: flex;
    cursor: pointer;
    gap: 2px;
  }
  .none {
    margin-left: 32px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
    text-align: left;
    color: #969799;

    @media (max-width: 1200px) {
      margin-left: 16px;
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

export const DeleteIcon = ({ fill = "black" }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 0.900391V1.90039H3H2.4H1V3.10039H2.4V13.5004C2.4 14.384 3.11634 15.1004 4 15.1004H12C12.8837 15.1004 13.6 14.384 13.6 13.5004V3.10039H15V1.90039H13.6H13H9.5V0.900391H6.5ZM12.4 3.10039H3.6V13.5004C3.6 13.7213 3.77909 13.9004 4 13.9004H12C12.2209 13.9004 12.4 13.7213 12.4 13.5004V3.10039ZM5.9 11.7504V5.25039H7.1V11.7504H5.9ZM8.9 5.25039V11.7504H10.1V5.25039H8.9Z"
      fill={fill}
    />
  </svg>
);

const SeedInfoButton = styled.div`
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.32px;
  text-align: right;
  color: #ff2b5d;
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  place-items: center;
  gap: 4px;
`;

export const LinkOutIcon = ({ fill = "#FF6A90" }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 1.2H2C1.55817 1.2 1.2 1.55817 1.2 2V10C1.2 10.4418 1.55817 10.8 2 10.8H10C10.4418 10.8 10.8 10.4418 10.8 10V7H12V10C12 11.1046 11.1046 12 10 12H2C0.895431 12 0 11.1046 0 10V2C0 0.895431 0.895431 0 2 0H5V1.2ZM11.7 0H7.72426C7.45699 0 7.32314 0.323142 7.51213 0.512131L9.07574 2.07574L5.57574 5.57574C5.34142 5.81005 5.34142 6.18995 5.57574 6.42426C5.81005 6.65858 6.18995 6.65858 6.42426 6.42426L9.92426 2.92426L11.4879 4.48787C11.6769 4.67686 12 4.54301 12 4.27574V0.3C12 0.134315 11.8657 0 11.7 0Z"
      fill={fill}
    />
  </svg>
);

const formatMonth = (month) => {
  return `${month.toString().padStart(2, "0")}월`;
};

const formatDate = (
  timestamp,
  hasYear = false,
  isTimeStamp = false,
  hasTime = false,
  timedelta = 9
) => {
  if (isTimeStamp) {
    timestamp = timestamp - timedelta * 60 * 60;
  }

  const date = isTimeStamp ? new Date(timestamp * 1000) : new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();

  return `${hasYear ? year + "년 " : ""}${month}월 ${day}일${
    hasTime ? ` ${hour}시 ${minute}분` : ""
  }`;
};

const MonthlyNote = React.memo(
  ({
    month = 1,
    data = null,
    setSelectedIndex = () => {},
    setIsOpen = () => {},
  }) => {
    const handleDelete = useCallback(() => {
      setSelectedIndex(month);
      setIsOpen(true);
    }, [month, setSelectedIndex, setIsOpen]);

    return (
      <MonthlyNoteItem
        className={`item-${month}`}
        $empty={data === null ? "empty" : ""}
      >
        <div className="month">{formatMonth(month)}</div>
        {data ? (
          <>
            <div className="trx_range">
              {`${formatDate(data.first_transaction * 1000)} ~ ${formatDate(
                data.last_transaction * 1000
              )}
            `}
            </div>
            <div className="delete" onClick={handleDelete}>
              삭제하기
              <DeleteIcon />
            </div>
          </>
        ) : (
          <div className="none">거래내역이 없습니다.</div>
        )}
      </MonthlyNoteItem>
    );
  }
);

const MonthlyNoteSection = ({ data, cuid, year, refetch = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [monthlyData, setMonthlyData] = useState(data);
  const { mutate, isSuccess, error } = useDeleteMyNoteTradeSnapshot();

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  useEffect(() => {
    setMonthlyData(data);
  }, [data]);

  return (
    <MonthlyNoteWarpper>
      {Array.from({ length: 12 }, (_, index) => (
        <MonthlyNote
          key={index}
          month={index + 1}
          data={monthlyData?.find((item) => item.month === index + 1)}
          setSelectedIndex={setSelectedMonth}
          setIsOpen={setIsOpen}
        />
      ))}
      <MyNoteModal
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
        title="매매일지 삭제"
        body={
          <>
            삭제된 데이터는 복구가 불가능합니다.
            <br />
            정말 삭제하시겠습니까?
          </>
        }
        onClickConfirm={() => {
          mutate({ cuid, year, month: selectedMonth });
          setIsOpen(false);
        }}
      />
    </MonthlyNoteWarpper>
  );
};

const SeedTableWrapper = styled.div`
  width: 100%;

  @media (max-width: 1200px) {
    min-width: 100%;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-family: Pretendard;

  @media (max-width: 1200px) {
    margin-top: 12px;
    font-size: 12px;
  }

  th, td {
    box-sizing: border-box;
    padding: 0 8px;
    text-align: right;
    height: 36px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.32px;

    &:nth-child(1) {
      width: 45%;
      text-align: left;
    }
    &:nth-child(2) {
      width: 40%;
      position: relative;
    }

    @media (max-width: 1200px) {
      padding: 0 4px;
      height: 32px;
      font-size: 11px;
      line-height: 13px;
    }
  }

  th {
    background: #f2f4f7;
    &:nth-child(1) {
      border-top-left-radius: 8px;
    }
    &:nth-child(3) {
      border-top-right-radius: 8px;
    }
  }

  td {
    font-size: 13px;
    font-weight: 400;
    line-height: 15.51px;
    height: 44px;
    border-bottom: 1px solid #f2f4f7;

    &:nth-child(3) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
      padding-right: 16px;

      svg {
        cursor: pointer;
      }
    }

    @media (max-width: 1200px) {
      font-size: 12px;
      line-height: 14px;
      height: 40px;
    }
  }

  .seed-text-wrapper {
    position: relative;

    .seed-text {
      position: absolute;
      right: 20px;
      top: 15px;
      font-size: 15px;
      font-weight: 400;
      text-align: right;

      @media (max-width: 1200px) {
        font-size: 13px;
        line-height: 15px;
        right: 12px;
        top: 12px;
      }
    }
  }

  // .edit-button {
  //   gap: 6px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: flex-end;
  //   height: 60px;
  // }

  .edit-button {
    display: flex;
    gap: 4px;
    padding: 8px 4px;
    
    @media (max-width: 768px) {
      padding: 4px 2px;
    }
  }

  tbody tr:first-child {
    background-color: #fffafb;
  }

  .edit {
    height: auto;

    @media (max-width: 1200px) {
    td{
    padding: 0 4px;
    }
  }
`;

const StyledInput = styled.input`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 8px 40px 8px 8px;
  border: 1px solid #e7e9ef;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 700;
  text-align: right;

  &::placeholder {
    color: #c8c9cc;
  }

  &:focus {
    outline: none;
    border: 1px solid #e7e9ef;
  }

  @media (max-width: 1200px) {
    height: 32px;
    padding: 8px 32px 8px 8px;
    font-size: 13px;
    line-height: 15px;
  }
`;

registerLocale("ko", ko);

const DatePickerWrapper = styled(StyledDatePickerWrapper)`
  /* 추가 스타일을 여기에 정의할 수 있습니다 */
  height: 0;
  .react-datepicker__navigation--next {
    right: 0;
    top: 20px;
    display: block;
  }
  .react-datepicker__navigation--previous {
    left: 0;
    top: 20px;
    display: block;
  }
  .react-datepicker__navigation-icon {
    position: static;
  }
  .react-datepicker-wrapper {
    width: 100%;
    padding: 0;
    text-align: left;
  }
  .react-datepicker__input-container input {
    padding: 11px 44px 11px 8px;
    border: 1px solid #e7e9ef;
    font-size: 15px;
    border-radius: 6px;
  }
  .react-datepicker__input-container input:focus {
    outline: none;
    border: 1px solid #e7e9ef;
    border-color: #e7e9ef;
  }

  .react-datepicker__day {
    &:hover {
      background: #f0f0f0;
      color: #4b4c4d;
      box-sizing: border-box;
      border-radius: 8px;
    }
  }

  .react-datepicker__day--keyboard-selected {
    background: none;
    color: #4b4c4d;
  }

  .react-datepicker__day--selected {
    border-radius: 50%;
    background-color: #ff3d6c;
    color: #fff;
    border: none;
  }
`;

const formatToHyphenDate = (dateString) => {
  if (!dateString) return "";
  const [year, month, day] = dateString
    .replace("년", "")
    .replace("월", "")
    .replace("일", "")
    .trim()
    .split(" ");
  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
};

const formatToKoreanDate = (dateString) => {
  if (!dateString) return "";
  const [year, month, day] = dateString.split("-");
  return `${year}년 ${parseInt(month)}월 ${parseInt(day)}일`;
};

const formatKoreanDateToDate = (dateString) => {
  if (!dateString) return "";
  const [year, month, day] = dateString
    .replace("년", "")
    .replace("월", "")
    .replace("일", "")
    .trim()
    .split(" ");
  const str = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;

  return moment(str, "YYYY-MM-DD").toDate();
};

const StyledDatePicker = ({
  currentDate,
  onDateSelect,
  includeDates,
  closePicker,
}) => {
  const [selectedDate, setSelectedDate] = useState();
  const DAY = 60 * 60 * 24 * 1000;
  const datePickerRef = useRef(null);

  useEffect(() => {
    setSelectedDate(
      currentDate ? formatKoreanDateToDate(currentDate) : new Date()
    );
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(new Date(date));
    onDateSelect(new Date(date));
  };

  const formatDay = (date) => {
    switch (date) {
      case "일요일":
        return "SUN";
      case "월요일":
        return "MON";
      case "화요일":
        return "TUE";
      case "수요일":
        return "WED";
      case "목요일":
        return "THU";
      case "금요일":
        return "FRI";
      case "토요일":
        return "SAT";
      default:
        return "";
    }
  };

  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      closePicker();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <DatePickerWrapper ref={datePickerRef}>
      <DatePicker
        inline={true}
        showPopperArrow={false}
        selected={selectedDate}
        onChange={handleDateChange}
        placeholderText=""
        locale={ko}
        dateFormat={"yyyy년 M월 d일"}
        formatWeekDay={formatDay}
        includeDates={includeDates}
        maxDate={new Date()}
      />
    </DatePickerWrapper>
  );
};

const SeedItem = ({
  date = "",
  seed = 0,
  index,
  isAdd = false,
  setIsAdd = () => {},
  setSelectedIndex = () => {},
  setIsOpen = () => {},
  refetchSeedHistory = () => {},
}) => {
  const [isEdit, setIsEdit] = useState(isAdd);
  const [dateValue, setDateValue] = useState(
    isAdd ? "날짜를 선택해 주세요." : formatDate(date, true)
  );

  const [seedValue, setSeedValue] = useState(seed);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const datePickerRef = useRef(null);

  const { mutate, isSuccess, reset } = useUpdateMyNoteSeedHistory();

  const handleSeedChange = useCallback((e) => {
    const value = e.target.value.replace(/[^0-9]/g, "").replace(/,/g, "");
    setSeedValue(Number(value) * 10000);
  }, []);

  useEffect(() => {
    if (!isEdit) {
      setSeedValue(seed);
      setDateValue(isAdd ? "날짜를 선택해 주세요." : formatDate(date, true));
    }
  }, [seed, date, isAdd, isEdit]);

  useEffect(() => {
    if (isSuccess) {
      setIsEdit(false);
      setIsAdd(false);
      setSeedValue(seed);
      setDateValue(formatDate(date, true));
      refetchSeedHistory();
      reset();
    }
  }, [isSuccess, setIsEdit, setIsAdd, refetchSeedHistory, seed, date, reset]);

  const handleEdit = useCallback(() => {
    setIsEdit(true);
  }, []); // 의존성 배열 비움

  return (
    <tr key={date + "-" + seed} className={isEdit ? "edit" : ""}>
      {isEdit ? (
        <>
          <td>
            <StyledInput
              type="text"
              placeholder={date || "날짜를 선택해 주세요."}
              style={{ textAlign: "left" }}
              readOnly
              value={dateValue}
              onClick={() => {
                setIsDatePickerOpen(true);
              }}
            />
            {isDatePickerOpen && (
              <StyledDatePicker
                ref={datePickerRef}
                closePicker={() => setIsDatePickerOpen(false)}
                onDateSelect={(value) => {
                  if (value) {
                    setDateValue(moment(value).format("YYYY년 M월 D일"));
                  } else {
                    setDateValue("날짜를 선택해 주세요.");
                  }
                  setIsDatePickerOpen(false);
                }}
                currentDate={
                  dateValue === "날짜를 선택해 주세요." ? null : dateValue
                }
              />
            )}
          </td>
          <td className="seed-text-wrapper">
            <StyledInput
              onChange={handleSeedChange}
              type="text"
              value={seedValue / 10000}
              placeholder={seed / 10000 || "금액을 입력해 주세요."}
            />
            <div className="seed-text">만원</div>
          </td>
        </>
      ) : (
        <>
          <td>{dateValue}</td>
          <td
            style={{
              color: seed < 0 ? "#FF3D6C" : "inherit",
              fontWeight: index === 0 ? 600 : 400,
            }}
          >
            {convertToNearWon(seed)}
          </td>
        </>
      )}
      <td className={isEdit ? "edit-button" : ""}>
        {isEdit ? (
          <div className={isEdit ? "edit-button" : ""}>
            <CellButtonWrapper
              $secondary
              onClick={() => (isAdd ? setIsAdd(false) : setIsEdit(false))}
            >
              취소
            </CellButtonWrapper>
            <CellButtonWrapper
              disabled={
                seedValue === 0 || dateValue === "날짜를 선택해 주세요."
              }
              onClick={() => {
                if (seedValue !== 0 && dateValue !== "날짜를 선택해 주세요.") {
                  mutate({
                    before_date: date,
                    after_date: formatToHyphenDate(dateValue),
                    seed: parseFloat(seedValue),
                  });

                  setIsEdit(false);
                }
              }}
            >
              저장
            </CellButtonWrapper>
          </div>
        ) : (
          <>
            <div onClick={handleEdit}>
              <EditIcon fill="#4B4C4D" />
            </div>

            {index === 0 ? (
              <div style={{ width: 16 }} />
            ) : (
              <div
                onClick={() => {
                  setSelectedIndex(index);
                  setIsOpen(true);
                }}
              >
                <DeleteIcon fill="#4B4C4D" />
              </div>
            )}
          </>
        )}
      </td>
    </tr>
  );
};

const TableAddButton = styled.div`
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.9px;
  text-align: left;
  color: #141414;
  width: 100%;
  height: 44px;
  background: #ffffff;
  border: 1px solid #e7e9ef;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;

  @media (max-width: 1200px) {
    font-size: 13px;
    line-height: 15px;
    height: 40px;
  }
`;

const SeedDeleteBodyWrapper = styled.div`
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
`;

const SeedTable = ({ data, refetchSeedHistory = () => {} }) => {
  const [isAdd, setIsAdd] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const {
    mutate: deleteSeedHistory,
    isSuccess: deleteSuccess,
    error: deleteError,
  } = useDeleteMyNoteSeedHistory({
    date: selectedIndex >= 0 ? data[selectedIndex]?.date : null,
  });

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (deleteSuccess) {
      refetchSeedHistory();
    }
  }, [deleteSuccess]);

  return (
    <SeedTableWrapper>
      <Table>
        <thead>
          <tr>
            <th>날짜</th>
            <th>투자원금</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <SeedItem
              {...item}
              index={index}
              setSelectedIndex={setSelectedIndex}
              setIsOpen={setIsOpen}
              key={item?.date}
            />
          ))}
          {isAdd && (
            <SeedItem
              refetchSeedHistory={refetchSeedHistory}
              index={data?.length + 1}
              isAdd={true}
              setIsAdd={setIsAdd}
            />
          )}
        </tbody>
      </Table>
      <TableAddButton
        onClick={() => {
          setIsAdd(true);
        }}
      >
        + 내역 추가
      </TableAddButton>
      {selectedIndex != -1 && (
        <MyNoteModal
          isOpen={isOpen}
          onCancel={closeModal}
          title="투자원금 삭제"
          body={
            <SeedDeleteBodyWrapper>
              <strong>
                날짜: {data[selectedIndex]?.date}
                <br />
                투자원금: {convertToNearWon(data[selectedIndex]?.seed)}
              </strong>
              <br />
              <br />
              삭제된 데이터는 복구가 불가능합니다.
              <br />
              정말 삭제하시겠습니까?
            </SeedDeleteBodyWrapper>
          }
          onClickConfirm={() => {
            deleteSeedHistory();
            closeModal();
          }}
        />
      )}
    </SeedTableWrapper>
  );
};

const UploadTableWrapper = styled.div``;

const UploadTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-family: Pretendard;

  th,
  td {
    box-sizing: border-box;
    padding: 0 8px;
    text-align: right;
    height: 36px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.32px;

    &:nth-child(1) {
      width: 110px;
      text-align: left;
    }
    &:nth-child(2) {
      width: 220px;
      text-align: left;
    }
    &:nth-child(3) {
      width: 180px;
      text-align: left;
    }
    &:nth-child(4) {
      width: 180px;
      text-align: left;
    }
    &:nth-child(5) {
      width: 80px;
    }
    &:nth-child(6) {
      width: 147px;
    }
    &:nth-child(7) {
      width: 147px;
    }

    @media (max-width: 1200px) {
      height: 32px;
      &:first-child {
        text-align: left;
      }
    }
  }

  th {
    background: #f2f4f7;
    &:nth-child(1) {
      border-top-left-radius: 8px;
    }
    &:nth-child(7) {
      border-top-right-radius: 8px;
    }
  }

  td {
    font-size: 13px;
    font-weight: 400;
    line-height: 15.51px;
    height: 44px;
    border-bottom: 1px solid #f2f4f7;
  }

  @media (max-width: 1200px) {
    th,
    td {
      &:nth-child(3), // 최초 시각
      &:nth-child(4), // 마지막 시각
      &:nth-child(5), // 일자 수
      &:nth-child(6) // 종목 수
      {
        display: none;
      }
      &:nth-child(6) {
        display: none;
      }
    }

    th {
      font-size: 11px;
      padding: 0 4px;
    }

    td {
      font-size: 12px;
      padding: 0 4px;
    }
  }
`;

const UploadTableSection = ({ data }) => {
  return (
    <UploadTableWrapper>
      <UploadTable>
        <thead>
          <tr>
            <th>분류</th>
            <th>시각</th>
            <th>최초 거래일</th>
            <th>마지막 거래일</th>
            <th>일자 수</th>
            <th>종목 수</th>
            <th>체결 건수</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index}>
              <td>{item.type === "upload" ? "업로드" : "수동"}</td>
              <td>{formatDate(item.time, true, false, true)}</td>
              <td>{formatDate(item.first_transaction, true, true)}</td>
              <td>{formatDate(item.last_transaction, true, true)}</td>
              <td>{item.n_date}일</td>
              <td>{item.n_stock}개</td>
              <td>{item.n_trx}건</td>
            </tr>
          ))}
        </tbody>
      </UploadTable>
    </UploadTableWrapper>
  );
};

const AccountWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .title {
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.51px;
    text-align: left;
    color: #7d7e80;
    margin-bottom: 8px;
  }
`;
const CustomStyledInput = styled.input`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 11px 44px 11px 8px;
  border: 1px solid #e7e9ef;
  border-radius: 6px;

  font-family: Pretendard;
  font-size: 15px;
  font-weight: 400;
  line-height: 17.9px;
  text-align: left;

  &::placeholder {
    color: #c8c9cc;
  }

  &:focus {
    outline: none;
    border: 1px solid #e7e9ef;
  }
`;
const AccountComponent = ({
  broker,
  account,
  nickname,
  setBroker,
  setAccount,
  setNickname,
}) => {
  return (
    <AccountWrapper>
      <div>
        <div className="title">증권사</div>
        <CustomStyledInput
          disabled={true}
          value={broker}
          onChange={(e) => setBroker(e.target.value)}
        />
      </div>
      <div>
        <div className="title">계좌번호</div>
        <CustomStyledInput
          value={account}
          onChange={(e) => setAccount(e.target.value)}
        />
      </div>
      <div>
        <div className="title">계좌 별명</div>
        <CustomStyledInput
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
        />
      </div>
    </AccountWrapper>
  );
};

const AlphaDetailMyNote = ({ cuid }) => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [isOpen, setIsOpen] = useState(false);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [isAccountOpen, setIsAccountOpen] = useState(false);
  const [broker, setBroker] = useState("");
  const [account, setAccount] = useState("");
  const [nickname, setNickname] = useState("");

  const {
    data: accountInfoData,
    isLoading: accountInfoLoading,
    error: accountInfoError,
  } = useMyNoteAccountInfo();

  const {
    data: seedHistoryData,
    isLoading: seedHistoryLoading,
    refetch: refetchSeedHistory,
  } = useMyNoteSeedHistory();

  const {
    data: historyAttachmentData,
    isLoading: historyAttachmentLoading,
    refetch: refetchHistoryAttachmentData,
  } = useMyNoteHistoryAttachment({
    type: "",
  });

  const {
    data: myNoteTradeSnapshotData,
    isLoading: myNoteTradeSnapshotLoading,
    refetch: refetchMyNoteTradeSnapshot,
  } = useMyNoteTradeSnapshot({
    cuid,
    year: currentYear,
  });

  const {
    mutate: updateMutate,
    isSuccess,
    isLoading: isUpdateLoading,
    data: updatedData,
  } = useUpdateMyNoteAccointInfo();

  const closeModal = () => {
    setIsOpen(false);
  };

  const closeAccountModal = () => {
    setIsAccountOpen(false);
  };

  const updateAccountInfo = () => {
    updateMutate({ nickname: nickname, account: account });
  };

  useEffect(() => {
    if (isSuccess) {
      setIsAccountOpen(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    refetchMyNoteTradeSnapshot();
  }, [cuid, currentYear]);

  useEffect(() => {
    if (accountInfoData) {
      setBroker(accountInfoData.account_info.broker);
      setAccount(accountInfoData.account_info.account);
      setNickname(accountInfoData.account_info.nickname);
    }
  }, [accountInfoData]);

  return (
    <DetailWrapper>
      <div className="account-section">
        <div className="icon">
          <img width={44} src={KiumLogo} alt="broker Logo" />
        </div>
        <div className="account-info">
          <div className="account-name">
            {`${accountInfoData?.account_info?.broker}증권 ${accountInfoData?.account_info?.account}`}
          </div>
          <div className="account-id">
            {accountInfoData?.account_info?.nickname}
            <div
              className="edit-icon"
              onClick={() => {
                setIsAccountOpen(true);
              }}
            >
              <EditIcon />
            </div>
          </div>
        </div>
      </div>
      <MyNoteModal isOpen={isOpen} onCancel={closeModal} />
      <MyNoteModal
        title="계정 설정"
        body={
          <AccountComponent
            broker={broker}
            account={account}
            nickname={nickname}
            setBroker={setBroker}
            setAccount={setAccount}
            setNickname={setNickname}
          />
        }
        onClickConfirm={updateAccountInfo}
        isOpen={isAccountOpen}
        onCancel={closeAccountModal}
      />

      <YearSection year={currentYear} setYear={setCurrentYear} />
      <div className="snapshot-section">
        <SectionTitle
          title="업로드 현황"
          lastUpdated={
            accountInfoData?.last_update
              ? moment(accountInfoData?.last_update).format("yyyy.MM.DD")
              : ""
          }
          description={
            <span>
              업로드한 매매일지 기준 <b>최초 거래일</b>과 <b>마지막 거래일</b>을
              표시합니다.
            </span>
          }
          rightChild={
            <ButtonWrapper
              onClick={() => {
                setIsUploadOpen(true);
              }}
            >
              매매일지 업로드
            </ButtonWrapper>
          }
        />
        <MyNoteUploadModal
          adModal
          isOpen={isUploadOpen}
          onCancel={() => {
            setIsUploadOpen(false);
          }}
          title="매매일지 업로드"
          refetchList={[
            refetchMyNoteTradeSnapshot,
            refetchHistoryAttachmentData,
          ]}
        />
        <MonthlyNoteSection
          data={myNoteTradeSnapshotData}
          cuid={cuid}
          year={currentYear}
          refetch={refetchMyNoteTradeSnapshot}
        />
      </div>
      <div className="seed-section">
        <SectionTitle
          title="투자금 관리"
          lastUpdated=""
          description={
            <span>
              초기 투자원금을 입력해 주시고, 대량의 입출금 내역으로 인해
              투자원금에 변동이 있었다면 추가해주세요.
            </span>
          }
          rightChild={
            <SeedInfoButton
              onClick={() =>
                window.open(
                  "https://www.notion.so/arpa/113ace638ed680f2ac21ee9f647058c9",
                  "_blank"
                )
              }
            >
              투자원금 확인하는 법<LinkOutIcon />
            </SeedInfoButton>
          }
        />
        <SeedTable
          data={seedHistoryData}
          refetchSeedHistory={refetchSeedHistory}
        />
      </div>
      <div className="upload-section">
        <SectionTitle title="업로드 기록" lastUpdated="" />
        <UploadTableSection data={historyAttachmentData} />
      </div>
    </DetailWrapper>
  );
};

export default AlphaDetailMyNote;
