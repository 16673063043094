import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import {
  convertToNearWon,
  convertToPercentage,
  convertToPnlRatio,
} from "utils/utils";

const ProfitTimeCardWrapper = styled.div`
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  height: 356px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ebedf5;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: left;

  .title {
    color: #141414;
  }
`;

const WinningRateCardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 0;

  background: #fff;
  border-radius: 8px;
  border: 1px solid #ebedf5;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: left;
`;

const WinningRateCardItem = styled.div`
  padding: 20px;

  display: flex;
  flex-direction: column;

  border: ${(props) =>
    props.isGrid ? "1px dashed #ebedf5" : "1px solid #EBEBEB"};

  ${(props) =>
    props.isGrid
      ? `
  &:nth-child(-n + 3) {
    border-top: none;
  }

  &:nth-child(n + 4) {
    border-bottom: none;
  }

  &:nth-child(3n + 1) {
    border-left: none;
  }

  &:nth-child(3n) {
    border-right: none;
  }
  `
      : `border-radius: 6px;`};

  .value {
    margin-top: 5px;
    font-family: Pretendard;
    font-size: 25px;
    font-weight: 600;
    line-height: 29.83px;
    text-align: left;
  }

  .infoWrapper {
    margin-top: 24px;
  }

  .info {
    color: #afb0b2;
    display: flex;
    justify-content: space-between;
    margin-top: 4px;

    &:first-child {
      margin-top: 0;
    }
  }

  .title {
    display: flex;
    align-items: center;
  }

  svg {
    margin-left: 3px;
  }
`;

const WinningGraphWrapper = styled.div`
  width: 100%;
  margin-top: auto;
`;

const ValueAreaGraphWrapper = styled.div`
  // width: calc(100% - 48px);
  // height: calc(100% - 71px);

  width: 100%;
  height: calc(100% - 71px);
  overflow: hidden; // 차트가 넘치지 않도록

  @media (max-width: 1200px) {
    height: 250px; // 모바일에서 적절한 높이 지정
  }
`;

const GraphSection = styled.div`
  width: 100%;
  padding: 0;
  overflow: hidden; // 차트가 넘치지 않도록

  @media (max-width: 1200px) {
    padding: 0;
  }
`;

const CardWrapper = styled.div`
  width: 316px;
  height: 470px;
  box-sizing: border-box;
  padding: 28px;
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid #ebe8e8;
  font-family: Pretendard;
  color: #141414;
  box-shadow: 0px 4px 14px 0px #ddc8c882;
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    width: 300px;
    min-height: 450px;
    padding: 24px;
  }

  @media (max-width: 1200px) {
    width: 100%;
    min-height: auto;
    padding: 20px;
  }

  .card-title-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;

    .tag {
      padding: 4px 8px;
      background: #e7ffde;
      border-radius: 4px;
      border: 1px solid #d5f2ca;
      border-radius: 100px;

      font-size: 12px;
      font-weight: 400;
      line-height: 14.32px;
      color: #2b840c;

      span {
        font-weight: 700;
      }
    }

    @media (max-width: 1200px) {
      margin-bottom: 12px;

      .tag {
        padding: 3px 6px;
        font-size: 11px;
        line-height: 13px;
      }
    }
  }

  .summary-section {
  }

  .graph-section {
    padding: 22px 0 32px;

    @media (max-width: 1200px) {
      padding: 16px 0 24px;
    }
  }

  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 21.48px;
    text-align: left;
  }

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    &.profit {
      margin-bottom: 8px;
    }
    &.ratio {
      margin: 9px 0 8px;
    }

    .name {
      font-size: 15px;
      font-weight: 400;
      line-height: 17.9px;
      text-align: left;

      color: #969799;
    }

    .value {
      font-size: 15px;
      font-weight: 400;
      line-height: 17.9px;
      color: #141414;

      &.profit {
        font-size: 28px;
        font-weight: 600;
        line-height: 33.41px;
        color: #000000;
      }

      &.ratio {
        font-size: 22px;
        font-weight: 500;
        line-height: 26.25px;
      }
    }
  }

  .line {
    width: calc(100% + 56px);
    margin-left: -28px;
    border: 1px dashed #dcdee3;

    @media (max-width: 1200px) {
      width: calc(100% + 48px);
      margin-left: -24px;
    }

    @media (max-width: 1200px) {
      width: calc(100% + 40px);
      margin-left: -20px;
    }
  }

  .bottom-section {
    margin-top: 24px;

    .title {
      font-size: 18px;
      font-weight: 600;
      line-height: 21.48px;

      @media (max-width: 1200px) {
        font-size: 16px;
        line-height: 19px;
      }
    }

    @media (max-width: 1200px) {
      margin-top: 20px;
    }
  }
`;

const ProfitTimeCard = ({ title, data }) => {
  // const [currentType, setCurrentType] = useState("percent");
  const currentType = "percent";

  return (
    <ProfitTimeCardWrapper>
      <div className="title">{title}</div>
      <ValueAreaGraphWrapper>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={data}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tickFormatter={(tickItem) => {
                const date = new Date(tickItem * 1000);
                const month = (date.getMonth() + 1).toString().padStart(2, "0"); // months are 0-based in JavaScript
                const day = date.getDate().toString().padStart(2, "0");
                return `${month}-${day}`;
              }}
            />
            <YAxis />
            <Tooltip cursor={{ fill: "transparent" }} />
            <Area
              type="monotone"
              dataKey={currentType === "percent" ? "ret" : "pnl"}
              stroke="#FF3D6C"
              fill="#FFD5E0"
            />
          </AreaChart>
        </ResponsiveContainer>
      </ValueAreaGraphWrapper>
    </ProfitTimeCardWrapper>
  );
};

export const PerformanceCard = ({ data }) => {
  const {
    est_pnl,
    est_seed,
    n_rank,
    n_total,
    n_tr,
    pnl_ratio,
    ret,
    win_rate,
    chart_ret,
  } = data;
  const gradientOffset = () => {
    const dataMax = Math.max(...chart_ret.map((i) => i.ret));
    const dataMin = Math.min(...chart_ret.map((i) => i.ret));
    if (dataMax <= 0) {
      return 0;
    } else if (dataMin >= 0) {
      return 1;
    } else {
      return dataMax / (dataMax - dataMin);
    }
  };
  const off = gradientOffset();
  // if current path is /mynote then isMyNote is true
  const isMyNote = window.location.pathname === "/mynote";

  return (
    <CardWrapper>
      <div className="card-title-section">
        <div className="title">성과</div>
        {/*  if isMyNote is true than do now show tag div */}

        {!isMyNote && (
          <div className="tag">
            {n_total}명 중 <span>{n_rank}위</span>
          </div>
        )}
      </div>

      <div className="summary-section">
        <div className="item profit">
          <div className="name">수익률</div>
          <div className="value profit">{convertToPercentage(ret)}</div>
        </div>
        <div className="item ">
          <div className="name">수익금</div>
          <div className="value ">{convertToNearWon(est_pnl)}</div>
        </div>
        <div className="item ">
          <div className="name">투자금</div>
          <div className="value ">{convertToNearWon(est_seed)}</div>
        </div>
      </div>
      <div className="graph-section">
        <GraphSection>
          <ResponsiveContainer width={"100%"} height={90}>
            <AreaChart
              width={window.innerWidth <= 1128 ? window.innerWidth - 80 : 260}
              height={90}
              data={chart_ret?.map((value) => ({ value: value.ret }))}
            >
              <defs>
                <linearGradient id="ret" x1="0" y1="0" x2="0" y2="1">
                  <stop offset={off} stopColor="#FF3D6C" />
                  {off !== 1 && <stop offset={off} stopColor="#7291FF" />}
                </linearGradient>
                <linearGradient id="positive" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset={0}
                    stopColor="rgba(255, 77, 184, 0.14)"
                    stopOpacity={1}
                  />
                  <stop offset={off} stopColor="#ffffff" stopOpacity={0.8} />
                  <stop offset={"100%"} stopColor="#7291FF" stopOpacity={0.8} />
                </linearGradient>
              </defs>

              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <YAxis
                interval={"preserveStartEnd"}
                unit={"%"}
                width={40}
                dataKey={"value"}
                axisLine={false}
                tickLine={false}
                fill="#C8C9CC"
                tick={(props) => {
                  const { x, y, payload } = props;
                  const fontSize = window.innerWidth <= 1128 ? 12 : 14;
                  return (
                    <text
                      fontFamily="Pretendard"
                      fontSize={fontSize}
                      fontWeight={400}
                      x={x}
                      y={y}
                      fill="#C8C9CC"
                      textAnchor="end"
                      dominantBaseline="middle"
                    >
                      {payload.value}%
                    </text>
                  );
                }}
              />

              <Area
                dataKey="value"
                stroke="url(#ret)"
                type="monotoneX"
                fill="url(#positive)"
                strokeWidth={2}
                baseLine={0}
                baseValue={0}
              />
            </AreaChart>
          </ResponsiveContainer>
        </GraphSection>
      </div>
      <div className="line" />
      <div className="bottom-section">
        <div className="title">핵심 지표</div>
        <div className="item ratio">
          <div className="name">손익비</div>
          <div className="value ratio">{convertToPnlRatio(pnl_ratio)}</div>
        </div>
        <div className="item ">
          <div className="name">승률</div>
          <div className="value ">{convertToPercentage(win_rate)}</div>
        </div>
        <div className="item ">
          <div className="name">매매</div>
          <div className="value ">{n_tr}건</div>
        </div>
      </div>
    </CardWrapper>
  );
};
