import ko from "date-fns/locale/ko";
import { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import styled from "styled-components";

import { CircularProgress, Snackbar } from "@mui/material";
import { ReactComponent as ShareIcon } from "assets/images/ico_share.svg";
import AlphaTag from "components/AlphaTag";
import ShareModal from "components/Modals/ShareModal";
import {
  useDeleteMyReport,
  useGetMyJournalRange,
  useUpdateMyReport,
} from "hooks/queries/useAlphaTraderDetailQuery";
import moment from "moment";
import { useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { TRADE_STYLE_TYPE } from "utils/consts";
import { formatDateToMD } from "utils/utils";
import { useCuid } from "./TraderDetail";

const UserSection = styled.div`
  padding-top: 43px;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  max-width: 600px;

  @media (max-width: 1200px) {
    padding: 16px 16px 0;
    width: calc(100% - 32px);
    max-width: 100%;
  }
`;

const UserName = styled.div`
  font-family: Pretendard;
  font-size: 28px;
  font-weight: 700;
  line-height: 33.41px;
  text-align: left;
  color: #fff;
  margin-bottom: 18px;

  @media (max-width: 1200px) {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 12px;
  }
`;

const UserInfo = styled.div`
  font-family: Pretendard;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.48px;
  text-align: left;
  color: #c8c9cc;

  span {
    font-weight: 600;
    text-align: left;
    color: #fff;
    margin-left: 4px;
  }

  @media (max-width: 1200px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const UserDataInfo = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 14.32px;
  text-align: left;
  margin-top: 6px;

  .league {
    margin-left: 8px;
    color: #939393;
  }

  .date {
    margin-left: 4px;
    color: #7d7e80;
  }

  .in-league {
    margin-left: 6px;
    font-weight: 400;
    color: #99ff75;
  }

  .tag {
    margin-left: 0;
    padding: 3px 6px;
  }

  .underline {
    text-decoration-style: dashed;
    text-decoration-line: underline;
    text-underline-position: under;
    color: #646566;
  }

  @media (max-width: 1200px) {
    font-size: 11px;
    line-height: 13px;
    margin-top: 4px;

    .league {
      margin-left: 6px;
    }

    .date {
      margin-left: 3px;
    }

    .tag {
      padding: 2px 4px;
    }
  }
`;

const ActionSection = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: 0;

  @media (max-width: 1200px) {
    right: 16px;
    top: 16px;
    z-index: 1;
  }

  .round-button {
    border: 1px solid #323233;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1200px) {
      width: 32px;
      height: 32px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

const Section = styled.div`
  background-color: #16191f;
  color: #fff;
  position: relative;
  min-height: 220px;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    min-height: 180px;
    padding: 12px;
  }
`;

const TabWrapper = styled.div`
  display: flex;
  background-color: #323233;
  width: fit-content;
  border-radius: 6px 6px 0px 0px;
  position: absolute;
  bottom: 0;

  @media (max-width: 1200px) {
    width: 100%;
    justify-content: stretch;
    border-radius: 0;
    left: 0;
    transform: none;
  }
`;

const TabItem = styled.div`
  width: 120px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: Pretendard;
  font-size: 14px;
  line-height: 16.71px;
  border-radius: 6px 6px 0px 0px;
  font-weight: ${(props) => (props?.$active === "true" ? "700" : "400")};
  color: ${(props) => (props?.$active === "true" ? "#000" : "#969799")};
  background-color: ${(props) =>
    props?.$active === "true" ? "#ffffff" : "#323233"};

  @media (max-width: 1200px) {
    flex: 1;
    width: auto;
    height: 32px;
    font-size: 13px;
    line-height: 15.5px;
  }
`;

const DETAIL_TAB = {
  home: "홈",
  detail: "상세분석",
  notes: "전체노트",
};

const MYNOTE_TAB = {
  home: "홈",
  detail: "상세분석",
  notes: "전체노트",
  setting: "일지관리",
};

const TabComponent = ({ setTab, selectedTab = "홈", isMyNote = false }) => {
  const [tabList, setTabList] = useState(isMyNote ? MYNOTE_TAB : DETAIL_TAB);
  const [activeTab, setActiveTab] = useState(DETAIL_TAB[selectedTab] || "홈");

  useEffect(() => {
    setTabList(isMyNote ? MYNOTE_TAB : DETAIL_TAB);
  }, [isMyNote]);

  useEffect(() => {
    setActiveTab(tabList[selectedTab] || "홈");
  }, [selectedTab, tabList]);

  return (
    <TabWrapper>
      {Object.entries(tabList).map(([key, value]) => (
        <TabItem
          key={key}
          $active={(value === activeTab).toString()}
          onClick={() => {
            setActiveTab(value);
            setTab(key);
          }}
        >
          {value}
        </TabItem>
      ))}
    </TabWrapper>
  );
};

const InfoWrapper = styled.div`
  position: absolute;
  display: flex;
  bottom: 20px;
  right: 0;
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.51px;
  text-align: right;
  color: #7d7e80;
  gap: 20px;

  @media (max-width: 1200px) {
    bottom: 48px;
    right: 16px;
    gap: 12px;
    font-size: 11px;
    line-height: 13px;
  }

  span {
    color: #ebedf5;
    margin-right: 4px;
  }
`;

const InfoComponent = ({ value }) => {
  return (
    <InfoWrapper>
      <div>
        <span>{value?.view}</span>view
      </div>
      <div>
        <span>{value?.like}</span>like
      </div>
      <div>
        <span>{value?.bookmark}</span>save
      </div>
      <div>
        <span>{value?.share}</span>share
      </div>
    </InfoWrapper>
  );
};

const DateSelectorWrapper = styled.div`
  display: flex;
  width: 400px;
  box-sizing: border-box;
  align-items: center;
  padding: 11px 7px 11px 12px;
  border-radius: 6px;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 400;
  line-height: 17.9px;
  text-align: left;
  border: 1px solid
    ${(props) => (props.$is_selecting === "true" ? "#FFFFFF" : "#4B4C4D")};
  cursor: pointer;
  background-color: ${(props) =>
    props.$is_selecting === "true" ? "#FFFFFF" : "#16191F"};

  @media (max-width: 1200px) {
    width: 100%;
    padding: 8px 6px 8px 10px;
    font-size: 13px;
    line-height: 15.5px;
    flex-wrap: wrap;
    gap: 4px;
  }

  .date-range-group {
    display: flex;
    align-items: center;

    @media (max-width: 1200px) {
      width: 100%;
      justify-content: space-between;
    }
  }

  .info {
    color: #7d7e80;
    margin-right: 9px;
    white-space: nowrap;

    @media (max-width: 1200px) {
      margin-right: 6px;
    }
  }

  .date {
    color: ${(props) =>
      props.$is_selecting === "true" ? "#000000" : "#4b4c4d"};
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (max-width: 1200px) {
      flex: 1;
      min-width: 0;
    }
  }

  .selected {
    color: ${(props) =>
      props.$is_selecting === "true" ? "#000000" : "#ffffff"};
  }

  .slash {
    color: #fff;
    margin: 0 12px;

    @media (max-width: 1200px) {
      margin: 0 8px;
    }
  }
`;

const DateSelectorModal = styled.div`
  background-color: #fff;
  margin-top: 18px;
  width: 807px;
  height: 414px;
  border-radius: 8px;
  z-index: 1000;
  position: absolute;
  display: flex;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: #141414;

  @media (max-width: 1200px) {
    width: calc(100vw - 32px);
    height: 300px;
    margin-top: 12px;
    transform: scale(0.9);
    transform-origin: top center;
  }

  .side {
    width: 210px;
    height: 100%;
    border-right: 1px solid #ededed;
    display: flex;
    flex-direction: column;

    @media (max-width: 1200px) {
      width: 140px;
      font-size: 12px;
    }
  }

  .section-title {
    box-sizing: border-box;
    height: 21px;
    background-color: #edededcc;
    padding: 6px 5px 6px 6px;
    font-size: 8px;
    font-weight: 400;
    line-height: 9.55px;
    text-align: left;

    &:first-child {
      border-radius: 6px 0 0 0;
    }

    @media (max-width: 1200px) {
      height: 18px;
      font-size: 7px;
      padding: 4px 5px;
    }
  }

  .fast-section {
    padding: 22px 0 18px 21px;
    display: flex;
    flex-direction: column;
    gap: 28px;
    flex: 1;

    @media (max-width: 1200px) {
      padding: 12px 0 12px 12px;
      gap: 16px;
    }
  }

  .fast-section-name {
    color: #141414;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    cursor: pointer;

    &:hover {
      color: #ff3d6c;
    }

    @media (max-width: 1200px) {
      font-size: 11px;
      line-height: 13px;
    }
  }

  .section-name {
    color: #141414;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;

    .close-icon {
      display: none;
      cursor: pointer;

      @media (max-width: 1200px) {
        transform: scale(0.8);
      }
    }

    &:hover .close-icon {
      display: inline;
    }

    &:hover {
      color: #ff3d6c;
    }

    @media (max-width: 1200px) {
      font-size: 11px;
      line-height: 13px;
    }
  }

  .date-section {
    padding: 22px 0 18px 21px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 2;
    overflow-y: auto;
    box-sizing: border-box;

    @media (max-width: 1200px) {
      padding: 12px 0 12px 12px;
      gap: 12px;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ededed;
      border-radius: 2px;
    }
  }

  .button-section {
    display: flex;
    gap: 8px;
    position: absolute;
    bottom: 24px;
    right: 24px;

    @media (max-width: 1200px) {
      bottom: 16px;
      right: 16px;
      gap: 6px;
    }
  }

  .button {
    width: 100px;
    height: 40px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.71px;
    text-align: left;
    color: #141414;
    background-color: #fff;
    box-sizing: border-box;
    border: 1px solid #ededed;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 1200px) {
      width: 80px;
      height: 32px;
      font-size: 12px;
      line-height: 14px;
      border-radius: 4px;
    }
  }

  .primary {
    background-color: #ff3d6c;
    color: #fff;
    border: none;
  }

  .react-datepicker {
    @media (max-width: 1200px) {
      padding: 12px;

      .react-datepicker__month {
        margin: 0;
      }

      .react-datepicker__day {
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
      }

      .react-datepicker__day-name {
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 10px;
      }

      .react-datepicker__current-month {
        font-size: 14px;
        margin-bottom: 16px;
      }

      .react-datepicker__navigation {
        top: 8px;
      }
    }
  }

  .react-datepicker__month-container {
    @media (max-width: 1200px) {
      width: auto;
    }
  }
`;

const StyledDatePickerWrapper = styled.div`
  .react-datepicker__navigation {
    top: 0px;
    height:22px;
  }

  .react-datepicker__navigation--next {
    right: 0;
    
  }
  .react-datepicker__navigation--previous {
    left: 0;
    
  }

  .react-datepicker-wrapper {
    padding: 0 16px 0 22px;
  }

  .react-datepicker__input-container input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    outline: none;
  }

  .react-datepicker__input-container input:focus {
    border-color: #007bff;
  }

  .react-datepicker-popper {
    z-index: 1000;
    border-radius: 8px;
  }

  .react-datepicker {
    border-radius: 8px;
    border: none;
    background-color: #ffffff;
    padding: 20px 24px 24px;
    display: flex;
  }

  .react-datepicker__header {
    background-color: #ffffff;
    border: none;
    padding: 5px 0 0 0;
  }

  .react-datepicker__current-month {
    margin-bottom: 22px;
    font-family: Pretendard;
    font-weight: 600;
    line-height: 14px;
    text-align: center;
    color: #323233;

font-size: 20px;
text-align: center;

  }

  .react-datepicker__day-names {
  margin-top:41px;
    display: flex;
    width: 100%;
    gap: 8px;
  }

  .react-datepicker__day-name {
    font-family: Pretendard;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 1.5px;
    text-align: center;
    align-content: center;
    margin: 0;
    width: 30px;
    height: 20px;
    color: #afb0b2;
  }

  .react-datepicker__week {
    display: flex;
    gap: 8px;
    margin: 0;
  }

  .react-datepicker__day {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: #4b4c4d;

    margin: 0;
    place-content: center;

    width: 30px;
    height: 30px;

    &.highlight-range {
      font-weight: 700;
    }
  }

  .react-datepicker__month {
    width: 258px;
    margin: 0;
    display: flex;
    gap: 8px;
    flex-direction: column;
    margin-top: 22px;
  }

  .react-datepicker__day--today {
    background-color: none;
    color: #FFD5E0;
    border-radius: 50%;
    margin:0;
    padding:0;

    border: 1px solid #ff2b5d;
    background: none;
    color: #4b4c4d;
    box-sizing: border-box;
  }


  .react-datepicker__day--disabled {
    color: #c8c9cc;
  }


  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range {
    background-color: #FFD5E0;
    padding: 0 0px;
    margin: 0 0px;
    borader-radius: 0;

    &:hover {
      background-color: #FFD5E0;
    }
  }

  .react-datepicker__day--today.react-datepicker__day--in-selecting-range {
    border-radius: 50%;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: #ff3d6c;
    color: #fff;
    border-radius: 50%;
    margin:0;
    padding:0;
  
  }

  .example-custom-input {
    width: 215px;
    font-family: Pretendard;
    font-size: 22px;
    font-weight: 600;
    line-height: 32.25px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      margin-left: 6px;
      padding: 6px;
      &:hover {
        path {
        stroke: none;
      }
    }
  }
`;

const Spinner = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <CircularProgress />
  </div>
);
const DeleteIcon = () => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.8101 0.853553C8.00536 0.658291 8.00536 0.341709 7.8101 0.146447C7.61483 -0.0488155 7.29825 -0.0488155 7.10299 0.146447L3.97827 3.27116L0.853553 0.146447C0.658291 -0.0488155 0.341709 -0.0488155 0.146447 0.146447C-0.0488155 0.341709 -0.0488155 0.658291 0.146447 0.853553L3.27116 3.97827L0.146468 7.10297C-0.0487941 7.29823 -0.0487941 7.61481 0.146468 7.81007C0.34173 8.00533 0.658312 8.00533 0.853574 7.81007L3.97827 4.68538L7.10297 7.81007C7.29823 8.00534 7.61481 8.00534 7.81007 7.81007C8.00534 7.61481 8.00534 7.29823 7.81007 7.10297L4.68538 3.97827L7.8101 0.853553Z"
      fill="#7D7E80"
    />
  </svg>
);
registerLocale("ko", ko);

const formatDate = (dateString) => {
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);
  return new Date(`${year}-${month}-${day}`);
};

const formatDate2 = (dateString) => {
  const year = dateString.substring(0, 4);
  const month = dateString.substring(5, 7);
  const day = dateString.substring(8, 10);
  return new Date(`${year}-${month}-${day}`);
};

const DateSelector = ({ data = {} }) => {
  const [isSelecting, setIsSelecting] = useState(false);
  const selectorRef = useRef(null);
  const { current } = data;
  const [reportHistory, setReportHistory] = useState(data?.report_history);
  const [startDate, setStartDate] = useState(
    current?.start ? formatDate(current?.start) : null
  );
  const [endDate, setEndDate] = useState(
    current?.end ? formatDate(current?.end) : null
  );

  const deleteFlag = useRef(false);
  const { cuid: myCuid, setCuid: setMyCuid } = useCuid();

  const { data: rangeData } = useGetMyJournalRange();
  const isDateInRange = (date) => {
    if (!rangeData) return false;
    const first = formatDate2(rangeData?.first);
    const last = formatDate2(rangeData?.last);
    return date >= first && date <= last;
  };
  const getDayClassName = (date) => {
    return isDateInRange(date) ? "highlight-range" : "";
  };

  const { mutate: deleteMutate } = useDeleteMyReport();
  const {
    mutate: updateMutate,
    isSuccess,
    isLoading: isUpdateLoading,
    data: updatedData,
  } = useUpdateMyReport();

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleClickOutside = (event) => {
    if (selectorRef.current && !selectorRef.current.contains(event.target)) {
      setIsSelecting(false);
    }
  };

  const today = new Date();
  // const theDayBeforeToday = today.setDate(today.getDate() - 1);
  const monthOpen = new Date();
  monthOpen.setMonth(monthOpen.getMonth() - 1);
  const filterWeekdays = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  useEffect(() => {
    const rh = data?.report_history;
    setReportHistory(rh);
    const item = rh?.completed?.find((item) => item.cuid === myCuid);

    if (item) {
      setStartDate(formatDate(item?.start));
      setEndDate(formatDate(item?.end));
    } else {
      setStartDate(current?.start ? formatDate(current?.start) : null);
      setEndDate(current?.end ? formatDate(current?.end) : null);
    }
  }, [myCuid, data, current]);

  useEffect(() => {
    if (isSuccess) {
      setIsSelecting(false);
      setMyCuid(updatedData?.cuid);
    }
  }, [isSuccess, updatedData, setMyCuid]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={selectorRef}>
      <DateSelectorWrapper
        $is_selecting={isSelecting?.toString()}
        onClick={() => {
          setIsSelecting(true);
        }}
      >
        <div className="date-range-group">
          <div className="info">시작일</div>
          <div className={`date ${startDate ? "selected" : ""}`}>
            {startDate
              ? moment(startDate).format("YYYY년 M월 D일")
              : "날짜를 선택해주세요"}
          </div>
        </div>
        <div className="date-range-group">
          {window.innerWidth > 1200 ? <div className="slash">-</div> : ""}
          <div className="info">종료일</div>
          <div className={`date ${endDate ? "selected" : ""}`}>
            {endDate
              ? moment(endDate).format("YYYY년 M월 D일")
              : "날짜를 선택해주세요"}
          </div>
        </div>
      </DateSelectorWrapper>
      {isSelecting && (
        <DateSelectorModal>
          <div className="side">
            <div className="section-title">빠른 선택</div>
            <div className="fast-section">
              <div
                className="fast-section-name"
                onClick={() => {
                  setStartDate(moment().startOf("isoWeek").toDate());
                  setEndDate(today);
                  // setEndDate(theDayBeforeToday);
                }}
              >
                이번 주
              </div>
              <div
                className="fast-section-name"
                onClick={() => {
                  setStartDate(moment().startOf("month").toDate());
                  setEndDate(today);
                  // setEndDate(theDayBeforeToday);
                }}
              >
                이번 달
              </div>
              <div
                className="fast-section-name"
                onClick={() => {
                  setStartDate(moment().startOf("year").toDate());
                  setEndDate(today);
                  // setEndDate(theDayBeforeToday);
                }}
              >
                이번 년
              </div>
            </div>
            <div className="section-title">최근 분석</div>
            <div className="date-section">
              {reportHistory?.completed?.length > 0 ? (
                reportHistory.completed.map((item, index) => (
                  <div
                    key={index}
                    className="section-name"
                    onClick={() => {
                      setMyCuid(item.cuid);
                      setStartDate(formatDate(item.start));
                      setEndDate(formatDate(item.end));
                      setIsSelecting(false);
                    }}
                  >
                    {`${moment(formatDate(item.start)).format(
                      "YYYY.M.D"
                    )} - ${moment(formatDate(item.end)).format("YYYY.M.D")}`}
                    <div
                      className="close-icon"
                      onClick={(event) => {
                        event.stopPropagation();
                        deleteMutate({ cuid: item.cuid });
                        // refetchMy();
                        if (item.cuid === myCuid) {
                          deleteFlag.current = true;
                        }
                      }}
                    >
                      <DeleteIcon />
                    </div>
                  </div>
                ))
              ) : (
                <div>최근 분석 기록이 없습니다</div>
              )}
            </div>
          </div>
          <div>
            <StyledDatePickerWrapper>
              <DatePicker
                inline
                monthsShown={2}
                selectsRange
                selectedDates={[startDate, endDate]}
                selectsMultiple
                focusSelectedMonth={true}
                disabledKeyboardNavigation
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                minDate={rangeData?.first ? formatDate2(rangeData?.first) : ""}
                maxDate={today}
                // maxDate={theDayBeforeToday}
                openToDate={monthOpen}
                filterDate={filterWeekdays}
                dayClassName={getDayClassName}
                renderCustomHeader={({
                  monthDate,
                  customHeaderCount,
                  decreaseMonth,
                  increaseMonth,
                }) => (
                  <div>
                    <button
                      aria-label="Previous Month"
                      className={
                        "react-datepicker__navigation react-datepicker__navigation--previous"
                      }
                      style={
                        customHeaderCount === 1
                          ? { visibility: "hidden" }
                          : null
                      }
                      onClick={decreaseMonth}
                    >
                      <span
                        className={
                          "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                        }
                      >
                        {"<"}
                      </span>
                    </button>
                    <span className="react-datepicker__current-month">
                      {monthDate.toLocaleString("ko", {
                        month: "long",
                        year: "numeric",
                      })}
                    </span>
                    <button
                      aria-label="Next Month"
                      className={
                        "react-datepicker__navigation react-datepicker__navigation--next"
                      }
                      style={
                        customHeaderCount === 0
                          ? { visibility: "hidden" }
                          : null
                      }
                      onClick={increaseMonth}
                    >
                      <span
                        className={
                          "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                        }
                      >
                        {">"}
                      </span>
                    </button>
                  </div>
                )}
              />
            </StyledDatePickerWrapper>
            <div className="button-section">
              <div
                className="button"
                onClick={() => {
                  setStartDate(
                    current?.start ? formatDate(current?.start) : null
                  );
                  setEndDate(current?.end ? formatDate(current?.start) : null);
                  setIsSelecting(false);
                }}
              >
                취소
              </div>
              <div
                className="button primary"
                onClick={() => {
                  if (!isUpdateLoading) {
                    updateMutate({
                      start: moment(startDate).format("YYYYMMDD"),
                      end: moment(endDate).format("YYYYMMDD"),
                    });
                  }
                }}
              >
                {isUpdateLoading ? (
                  <CircularProgress size={28} color="white" />
                ) : (
                  "분석"
                )}
              </div>
            </div>
          </div>
        </DateSelectorModal>
      )}
    </div>
  );
};

const TitleSection = ({
  data,
  setTab,
  selectedTab,
  isMyNote = false,
  refetchMy,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const { cuid: myCuid, setCuid: setMyCuid } = useCuid();

  const handleSnackClick = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const { competition: leagueData, cnt } = data || {};

  return (
    <Section>
      <UserSection>
        <UserName>{data.trader?.nickname || data.trader?.nick}</UserName>
        {isMyNote && <DateSelector data={data} refetchMy={refetchMy} />}
        {!isMyNote && (
          <>
            {data?.competition?.cid === "알파노트" ? (
              <DateSelectorWrapper $is_selecting="false" disabled="true">
                <div className="info">시작일</div>
                <div className={`selected`}>
                  {data?.competition?.start
                    ? moment(data?.competition?.start * 1000).format(
                        "YYYY년 M월 D일"
                      )
                    : "날짜를 선택해주세요"}
                </div>
                {window.innerWidth > 1200 ? <div className="slash">-</div> : ""}
                <div className="info">종료일</div>
                <div className={`selected`}>
                  {data?.competition?.start
                    ? moment(data?.competition?.end * 1000).format(
                        "YYYY년 M월 D일"
                      )
                    : "날짜를 선택해주세요"}
                </div>
              </DateSelectorWrapper>
            ) : (
              <>
                <UserInfo>
                  {data.trader.league + "리그"}
                  <span>{data.trader.rank + "위"}</span>
                  {" | "}
                  {"투자스타일"}
                  <span>{TRADE_STYLE_TYPE[data.style]}</span>
                </UserInfo>
                <UserDataInfo>
                  <AlphaTag className="tag" is_dark={"dark"}>
                    {"기준데이터"}
                  </AlphaTag>
                  <span className="underline">
                    <span className="league">
                      {leagueData?.broker +
                        "증권 " +
                        leagueData?.section +
                        " " +
                        leagueData?.name}
                    </span>
                    <span className="date">
                      {formatDateToMD(leagueData?.start, 9) +
                        " " +
                        formatDateToMD(leagueData?.end, 9)}
                    </span>
                  </span>
                  {leagueData?.on_going && (
                    <span className="in-league">대회중</span>
                  )}
                </UserDataInfo>
              </>
            )}
          </>
        )}
      </UserSection>

      <ActionSection>
        {/* <div>스크랩</div>
        <div> | </div> */}
        <div className="round-button" onClick={() => openModal()}>
          <ShareIcon />
        </div>
        <ShareModal
          isOpen={isOpen}
          onCancel={closeModal}
          url={`${window.location.host}/trader/${myCuid}#${
            selectedTab === "setting" ? "" : selectedTab
          }`}
          setSnackOpen={setSnackOpen}
        />
      </ActionSection>
      <TabComponent
        setTab={setTab}
        selectedTab={selectedTab}
        isMyNote={isMyNote}
      />
      <InfoComponent value={cnt} />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackOpen}
        autoHideDuration={1000}
        onClose={handleSnackClose}
        message="클립보드에 복사되었습니다"
      />
    </Section>
  );
};

export default TitleSection;
