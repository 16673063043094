import { ReactComponent as CloseIcon } from "assets/images/ico_close_m.svg";
import ReactModal from "react-modal";
import styled from "styled-components";

const customStyles = {
  overlay: {
    zIndex: 1000,
    backgroundColor: "#00000070",
  },
  content: {
    width: "90%",
    maxWidth: "calc(100% - 32px)",
    inset: "unset",
    margin: "50vh auto",
    padding: 0,
    transform: "translateY(-50%)",
    position: "relative",

    borderRadius: "8px",
    "@media (max-width: 1200px)": {
      margin: "30vh auto", // 모바일에서는 상단에 더 가깝게
    },
  },
};

const ModalWrapper = styled.div`
  border-radius: 8px;
  font-family: Pretendard;
  text-align: left;
  overflow: hidden;
`;
const ModalHeader = styled.div`
  padding: 20px 20px 12px;
  display: flex;
  justify-content: space-between;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.29px;

  @media (max-width: 1200px) {
    padding: 16px;
    font-size: 16px;
    line-height: 19px;
  }

  svg {
    cursor: pointer;
    width: 24px;
    height: 24px;

    @media (max-width: 1200px) {
      width: 20px;
      height: 20px;
    }
  }
`;

const ModalBody = styled.div`
  padding: 20px 12px;
  display: flex;
  font-family: Pretendard;

  font-size: 15px;
  font-weight: 400;
  line-height: 17.9px;
  text-align: left;

  @media (max-width: 1200px) {
    padding: 12px;
    flex-direction: column;
    gap: 8px;
  }

  input {
    width: 376px;
    border: 1px solid #e7e9ef;
    border-radius: 8px 0 0 8px;
    padding: 8px;
  }

  .input-wrapper {
    flex: 1;

    @media (max-width: 1200px) {
      width: 100%;
    }
  }

  input {
    width: 376px;
    border: 1px solid #e7e9ef;
    border-radius: 8px 0 0 8px;
    padding: 8px;
    font-size: 14px;

    @media (max-width: 1200px) {
      width: 100%;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 12px;
    }
  }

  .button {
    font-size: 14px;
    font-weight: 600;
    line-height: 16.71px;
    width: 82px;
    border: 1px solid #e7e9ef;
    border-left: none;
    border-radius: 0 8px 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    @media (max-width: 1200px) {
      width: 100%;
      border: 1px solid #e7e9ef;
      border-radius: 8px;
      padding: 12px;
      margin-top: 8px;
      background: #f5f6f8;
    }

    &:active {
      background: #ecedef;
    }
  }
`;

const ShareModal = ({
  isOpen,
  onCancel,
  url = "",
  setSnackOpen = () => {},
}) => {
  const handleClickCancel = () => {
    // console.log("handleClickCancel");
    onCancel();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={handleClickCancel}
    >
      <ModalWrapper>
        <ModalHeader>
          <div>링크 공유</div>
          <CloseIcon onClick={handleClickCancel} />
        </ModalHeader>
        <ModalBody>
          <div className="input-wrapper">
            <input type="text" value={url} readOnly />
          </div>
          <div
            className="button"
            onClick={() => {
              navigator.clipboard.writeText(url);
              setSnackOpen(true);
            }}
          >
            복사
          </div>
        </ModalBody>
      </ModalWrapper>
    </ReactModal>
  );
};

export default ShareModal;
