import HotStockTitle from "assets/images/hotstock_title.png";
import TradeToggle from "components/TradeToggle";

import { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

import { ReactComponent as ButtonTop } from "assets/images/btn_top.svg";
import { ReactComponent as ArrowLeft } from "assets/images/ico_arrow_left_s.svg";
import { ReactComponent as ArrowRight } from "assets/images/ico_arrow_right_s.svg";
import { ReactComponent as TriangleBullet } from "assets/images/ico_triangle.svg";
import { useSelectedCompetition } from "utils/store";
import { formatDateToYMD, unescapeHtml } from "utils/utils";

import { useHotStocksQuery } from "hooks/queries/useHomeQuery";

import EmblaCarousel from "components/EmblaCarousel";
import StyledDatePicker from "components/StyledDatePicker";

import "react-datepicker/dist/react-datepicker.css";

import { useNavigate } from "react-router-dom";
import { TRADE_STYLE } from "utils/consts";

import "react-datepicker/dist/react-datepicker.css";

import { AlphaChart } from "components/AlphaChart";
import AlphaHotStockCard from "components/AlphaHotStockCard";
import AlphaStockTraderCard from "components/AlphaStockTraderCard";
import ToggleComponet from "components/ToggleComponet";
import {
  useAlphaStockGetChartQuery,
  useAlphaStockHotQuery,
} from "hooks/queries/useAlphaHotStockQuery";

const StockCardSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  color: #000;
  margin-bottom: 131px;
`;
const DatePickerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0 26px;
  cursor: pointer;

  .customDatePicker {
    background-color: #f8f9fa;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
  }

  .datePicker {
    display: flex;
    align-items: center;
    border: 1px solid colors.$GRAY6;
    border-radius: 4px;

    box-sizing: border-box;
    width: 100%;
    height: 46px;

    text-align: center;
    padding-right: 14px;

    font-family: Pretendard;
    font-size: 22px;
    font-weight: 600;
    line-height: 26.25px;
    text-align: center;

    box-shadow: 2px 16px 19px 0px #00000017;
  }

  svg {
    padding: 0 16px;
  }
`;

const BackgroundWrapper = styled.div`
  background-color: ${(props) => props.color || "#fff"};
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StockListWrapper = styled.div`
  margin-bottom: 78px;

  .title {
    margin: 28px 0 45px 0;
    font-family: Pretendard;
    font-size: 42px;
    font-weight: 600;
    line-height: 54.6px;
    text-align: center;
  }

  .title img {
    width: 622px;
    height: 119px;
  }

  .stock-info {
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.51px;
    text-align: right;
    color: #a3a4a6;
    margin-top: 10px;
  }
`;

const StockGrid = styled.div`
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: column;
  gap: 16px;
`;

const TradersSection = styled.div`
  width: 100%;
  max-width: 1128px;

  font-family: Pretendard;
  position: relative;

  .button-top {
    position: absolute;
    top: 48px;
    right: 0;
    z-index: 100;
    cursor: pointer;
  }

  .trader-title {
    margin-top: 60px;
    font-size: 34px;
    font-weight: 600;
    line-height: 40.57px;
    text-align: left;
  }

  .toggle {
    margin-top: 34px;
  }

  .cart-list {
    margin-top: 28px;
    display: flex;
    border-collapse: collapse;
    border-spacing: 0;
    width: fit-content;
    border-radius: 8px;
    border: 1px solid #dcdee3;
  }

  .chart-section {
    margin: 30px 0 50px;
  }

  .chart-top {
    display: flex;

    flex-direction: row;
    justify-content: space-between;

    font-family: Pretendard;
    font-size: 20px;
    font-weight: 700;
    line-height: 23.87px;
    text-align: left;
  }

  .alpha-note {
    svg {
      margin-right: 6px;
    }
  }

  .chart {
    margin-top: 26px;
  }

  .link {
    color: #0879ff;
    cursor: pointer;
    margin-left: 12px;
    font-size: 15px;
    font-weight: 500;
    line-height: 17.9px;
    text-align: left;
    svg {
      margin-top: 3px;
    }
  }
`;

const ArrowRightFilled = ({ stroke }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 1.5L9 6.15517L4 10.5"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DatePickerSection = ({ currentDate, onDateSelect, includeDates }) => {
  const [selectedDate, setSelectedDate] = useState(currentDate);

  useEffect(() => {
    setSelectedDate(currentDate);
  }, [currentDate]);

  const handlePrevDate = () => {
    // handleDateChange(new Date(selectedDate.getTime() - DAY));
    // currentDate to prev date in includeDates except already index 0
    const index = includeDates.findIndex(
      (date) => date === formatDateToYMD(selectedDate)
    );

    if (index > 0) {
      handleDateChange(new Date(includeDates[index - 1]));
    }
  };

  const handleNextDate = () => {
    const index = includeDates.findIndex(
      (date) => date === formatDateToYMD(selectedDate)
    );
    if (index < includeDates.length - 1) {
      handleDateChange(new Date(includeDates[index + 1]));
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onDateSelect(date);
  };

  return (
    <DatePickerWrapper>
      <ArrowLeft onClick={handlePrevDate} />
      <StyledDatePicker
        currentDate={currentDate}
        onDateSelect={onDateSelect}
        includeDates={includeDates}
      />
      <ArrowRight onClick={handleNextDate} />
    </DatePickerWrapper>
  );
};

const TestDaily = () => {
  const navigate = useNavigate();
  const [toggleState, setToggleState] = useState("1억");
  const [treaderType, setTraderType] = useState(TRADE_STYLE[0].type);
  const [isNoticeOpen, setNoticeOpen] = useState(false);

  const { selectedCompetition } = useSelectedCompetition((state) => state);
  const cid = selectedCompetition?.cid;
  const param = cid ? { cid } : {};
  const yesterday = new Date().setDate(new Date().getDate() - 1);

  const OPTIONS = { loop: true };
  const { data: hotStocksBelow } = useHotStocksQuery();

  const [acceptableDates, setAcceptableDates] = useState(
    selectedCompetition?.dates
  );

  const [currentDate, setCurrentDate] = useState(
    acceptableDates?.length > 0
      ? acceptableDates[acceptableDates.length - 1]
      : yesterday
  );

  const [selectedCard, setSelectedCard] = useState(0);
  const [selectedTrader, setSelectedTrader] = useState(0);
  const [selectedStock, setSelectedStock] = useState();
  const [selectedTraderInfo, setSelectedTraderInfo] = useState();
  const [currentType, setCurrentType] = useState(5);

  const { data: hotData } = useAlphaStockHotQuery({
    cid: selectedCompetition?.cid,
    date: formatDateToYMD(currentDate),
  });

  const { data: chartData, refetch } = useAlphaStockGetChartQuery({
    date: formatDateToYMD(currentDate),
    code: selectedStock?.code,
    unit: currentType,
    name: selectedStock?.name,
  });

  useEffect(() => {
    const aAates = selectedCompetition?.dates;

    if (aAates) {
      setAcceptableDates(aAates);
      setCurrentDate(aAates[aAates.length - 1]);
    }
  }, [selectedCompetition]);

  const hotStocks = hotData?.stocks;
  const sectionRef = useRef(null);

  useEffect(() => {
    const aAates = selectedCompetition?.dates;

    if (aAates) {
      setAcceptableDates(aAates);
      setCurrentDate(aAates[aAates.length - 1]);
    }
  }, [selectedCompetition]);

  useEffect(() => {
    setSelectedStock(hotData?.stocks[selectedCard]);
    setSelectedTrader(0);
    setSelectedTraderInfo(
      hotData?.stocks[selectedCard].participant[toggleState][selectedTrader]
    );
  }, [selectedCard, hotData]);

  useEffect(() => {
    setSelectedTraderInfo(
      hotData?.stocks[selectedCard].participant[toggleState][selectedTrader]
    );
  }, [selectedTrader]);

  useEffect(() => {
    setSelectedTrader(0);
    setSelectedTraderInfo(
      hotData?.stocks[selectedCard].participant[toggleState][0]
    );
  }, [toggleState]);

  useEffect(() => {
    refetch({
      code: selectedStock?.code,
      date: formatDateToYMD(currentDate),
      unit: currentType,
      name: selectedStock?.name,
    });
  }, [currentType]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollToSection = () => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Wrapper>
      <BackgroundWrapper>
        <img src={"/assets/images/daily/주도주_탑.png"} alt="주도주_탑" />
        <StockCardSection>
          {hotStocksBelow && (
            <EmblaCarousel
              slides={hotStocksBelow?.stocks?.map((item) => ({
                name: item.name,
                daily_change: item.daily_change,
                daily_change_ratio: item.daily_change_ratio,
                n_ranker: item.n_ranker,
                close: item.close,
                code: item.code,
              }))}
              options={OPTIONS}
            />
          )}
        </StockCardSection>
        <img src={"/assets/images/daily/주도주_바텀.png"} alt="주도주_바텀" />

        <StockListWrapper>
          <div className="title">
            <img src={HotStockTitle} alt="hotstock-title" />
          </div>

          <DatePickerSection
            onDateSelect={(date) => setCurrentDate(date)}
            currentDate={currentDate}
            includeDates={acceptableDates}
          />

          <StockGrid>
            {hotStocks?.map((data, index) => (
              <AlphaHotStockCard
                key={index}
                data={data}
                onClick={() => {
                  setSelectedCard(index);
                  scrollToSection();
                }}
                selected={selectedCard === index}
                idx={index}
                code={data.code}
                name={data.name}
                n_ranker={data.n_ranker}
                price={data.close}
                daily_change={data.daily_change}
                daily_change_ratio={data.daily_change_ratio}
                turnover={data.turnover}
              />
            ))}
          </StockGrid>
          <div className="stock-info">
            ※알파노트에서 제공하는 데이터는 실제 데이터와 차이가 있을 수
            있습니다.
          </div>
        </StockListWrapper>

        <BackgroundWrapper color="#F7F8FA" ref={sectionRef}>
          <TradersSection>
            <ButtonTop className="button-top" onClick={scrollToTop} />
            <div className="trader-title">
              {selectedStock?.name}를 매매한 트레이더 TOP5
            </div>
            <div className="toggle">
              <TradeToggle
                setToggleState={setToggleState}
                tabNames={[
                  "1억 리그",
                  "5천 리그",
                  "3천 리그",
                  "1천 리그",
                  "1백 리그",
                ]}
                toggleState={["1억", "5천", "3천", "1천", "1백"]}
              />
            </div>
            <div className="cart-list">
              {hotData?.stocks[selectedCard]?.participant[toggleState].map(
                (data, index) => (
                  <AlphaStockTraderCard
                    position={
                      index === 0
                        ? "left"
                        : index ===
                          hotData?.stocks[selectedCard]?.participant[
                            toggleState
                          ].length -
                            1
                        ? "right"
                        : ""
                    }
                    key={index}
                    idx={index}
                    nick={data.nick}
                    n_tr={data.n_tr}
                    avg_hold_time={data.avg_hold_time}
                    pnl={data.pnl}
                    ret={data.ret}
                    amt={data.max_amt_buy}
                    style={data.style}
                    selected={index === selectedTrader}
                    onClick={setSelectedTrader}
                  />
                )
              )}
            </div>
            <div className="chart-section">
              <div className="chart-top">
                <div className="alpha-note">
                  <span>
                    <TriangleBullet />
                    {selectedTraderInfo?.nick}
                  </span>
                  님의 {selectedStock?.name} Buy & Sell
                  <span
                    className="link"
                    onClick={() => {
                      window.open(
                        `/note?cuid=${selectedTraderInfo.note_id.cuid}&code=${selectedTraderInfo.note_id.code}&date=${selectedTraderInfo.note_id.date}`,
                        "newWindow",
                        "width=1300,height=1200"
                      );
                    }}
                  >
                    자세한 알파노트 보러가기
                    <ArrowRightFilled stroke={"#0879FF"} />
                  </span>
                </div>
                <div className="cart-toggle">
                  <ToggleComponet
                    onClick={(type) => {
                      setCurrentType(type);
                    }}
                    labels={[
                      { name: "1분", type: "1", value: "1" },
                      { name: "3분", type: "3", value: "3" },
                      { name: "5분", type: "5", value: "5" },
                      { name: "30분", type: "30", value: "30" },
                    ]}
                    initType={currentType}
                  />
                </div>
              </div>
              <div>
                <div className="chart">
                  {/* {isLoading ? (
                      <div className="chart-loading"></div>
                    ) : ( */}
                  <AlphaChart
                    key={"min"}
                    chartData={chartData?.chart}
                    markersData={selectedTraderInfo?.markers[currentType]}
                    name={unescapeHtml(selectedStock?.name) || ""}
                  />
                  {/* )} */}
                </div>
              </div>
            </div>
          </TradersSection>
        </BackgroundWrapper>
      </BackgroundWrapper>
    </Wrapper>
  );
};

export default TestDaily;
