import { SvgStkImg } from "components/StockIcon";
import { useState } from "react";
import styled from "styled-components";
import {
  convertToNearWon,
  convertToPercentage,
  formatTimestampToMD,
  unescapeHtml,
} from "utils/utils";
import { AlphaChart } from "./AlphaChart";
import { HistoryArea } from "./AlphaNoteHistoryArea";
import ToggleComponet from "./ToggleComponet";

import { ReactComponent as Complete } from "assets/images/ico_complete.svg";
import { useAlphaNoteChartQuery } from "hooks/queries/useAlphaNoteQuery";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { CalendarButtons } from "./CalendarButtons";

const StockInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Pretendard;
  margin-bottom: 50px;
  align-items: end;

  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
    align-items: flex-start;
  }

  .stock-info {
    display: flex;

    @media (max-width: 1200px) {
      width: 100%;
    }

    .stock-wrapper {
      margin-left: 12px;
      .stock-title {
        font-size: 25px;
        font-weight: 600;
        line-height: 29.83px;
        text-align: left;
        color: #000000;

        @media (max-width: 1200px) {
          font-size: 20px;
          line-height: 24px;
        }
      }

      .date-wrapper {
        margin-top: 2px;
        display: flex;
        flex-direction: row;

        @media (max-width: 1200px) {
          flex-wrap: wrap;
          gap: 8px;
        }

        .date-current {
          font-size: 16px;
          font-weight: 400;
          line-height: 19.09px;
          text-align: left;
          color: #4b4c4d;
          margin-right: 8px;
          align-self: center;

          @media (max-width: 1200px) {
            font-size: 14px;
            line-height: 17px;
          }
        }

        .date-info {
          font-size: 16px;
          font-weight: 400;
          line-height: 19.09px;
          text-align: left;
          margin-left: 8px;
          color: #c8c9cc;
          align-self: center;
        }
      }
    }
  }

  .user-info {
    margin-bottom: 2px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: right;
    color: #afb0b2;
    display: flex;

    @media (max-width: 1200px) {
      width: 100%;
      display: grid;
      font-size: 14px;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;
    }

    .info {
      margin-left: 26px;
      @media (max-width: 1200px) {
        margin-left: 0;
        font-size: 14px;
        line-height: 17px;
      }
    }

    span {
      margin-left: 8px;
      color: #000000;
    }
    .positive {
      color: #ff2727;
    }
    .negative {
      color: #000000;
    }
  }

  .clear-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3bad54;
    gap: 2px;

    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: center;
  }
`;
const ChartAreaWrapper = styled.div`
  width: 100%;
  background: #fff;
  padding: 28px;
  border-radius: 8px;
  max-width: 1128px;
  border: 1px solid #e7e9ef;
  box-sizing: border-box;
  font-family: Pretendard;

  @media (max-width: 1200px) {
    padding: 16px;
    border-radius: 4px;

    // 모바일에서 차트가 화면에 꽉 차도록 조정
    width: 100vw;
    margin: 0 -16px; // 패딩값을 네거티브 마진으로 상쇄
    border-left: none;
    border-right: none;
  }
  .chart-section-day {
    margin-bottom: 60px;

    @media (max-width: 1200px) {
      margin-bottom: 40px;
    }
  }

  .chart-section-min {
    margin-bottom: 90px;

    @media (max-width: 1200px) {
      margin-bottom: 40px;
    }
  }

  .chart-name {
    font-size: 15px;
    font-weight: 600;
    line-height: 17.9px;
    text-align: left;

    @media (max-width: 1200px) {
      font-size: 14px;
      line-height: 17px;
    }
  }

  .cart-toggle {
    display: flex;
    justify-content: space-between;
  }

  .chart {
    margin-top: 16px;

    @media (max-width: 1200px) {
      margin-left: -28px;
      margin-bottom: 12px 0;
    }
  }

  .chart-loading {
    height: 393px;

    @media (max-width: 1200px) {
      height: 250px;
    }
  }

  .trs {
    margin-bottom: 8px;

    @media (max-width: 1200px) {
      margin-bottom: 12px;
    }
  }
`;

const AlphaNoteStockInfo = ({
  name = "te",
  code = "",
  currentDate = "24년 6월 25일 ~ 27일",
  dateCandidates = ["1", "2"],
  pnl = "123",
  seed = "123",
  ret = "12",
  start = "",
  end = "",
  clear = false,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const scuid = searchParams.get("cuid"); // test
  const scode = searchParams.get("code"); // test
  const sdate = searchParams.get("date"); // test
  const currentIndex = dateCandidates.findIndex((date) => date === currentDate);

  return (
    <StockInfoWrapper>
      <div className="stock-info">
        <SvgStkImg code={code} name={name} size={52} />
        <div className="stock-wrapper">
          <div className="stock-title">{name}</div>
          <div className="date-wrapper">
            <div className="date-current">
              {formatTimestampToMD(start)}
              {formatTimestampToMD(end) === formatTimestampToMD(start)
                ? ""
                : " ~ " + formatTimestampToMD(end)}
            </div>
            {clear && (
              <div className="clear-wrapper">
                <Complete />
                매매완료
              </div>
            )}
            <CalendarButtons
              idx={currentIndex}
              total={dateCandidates.length}
              handlePrevDate={() => {
                navigate({
                  pathname: location.pathname,
                  search: `?${createSearchParams({
                    cuid: scuid,
                    code: scode,
                    date: `${dateCandidates[currentIndex - 1]}`,
                  })}`,
                });
              }}
              handleNextDate={() => {
                navigate({
                  pathname: location.pathname,
                  search: `?${createSearchParams({
                    cuid: scuid,
                    code: scode,
                    date: `${dateCandidates[currentIndex + 1]}`,
                  })}`,
                });
              }}
            />
            <div className="date-info">{`${currentIndex + 1}/${
              dateCandidates.length
            }`}</div>
          </div>
        </div>
      </div>
      <div className="user-info">
        <div className="info">
          투자금 <span>{convertToNearWon(seed)}</span>
        </div>
        <div className="info">
          수익금 <span>{convertToNearWon(pnl)}</span>
        </div>
        <div className="info">
          수익률
          <span className={ret >= 0 ? "positive" : "negative"}>
            {convertToPercentage(ret)}
          </span>
        </div>
      </div>
    </StockInfoWrapper>
  );
};

export const AlphaNoteChartArea = ({ data = {} }) => {
  const [currentType, setCurrentType] = useState("5");
  const {
    data: minChartData,
    isError,
    error,
    isLoading,
    refetch,
  } = useAlphaNoteChartQuery({
    note_id: { ...data?.note_id, unit: currentType },
  });

  const stockInfo = data?.info;
  const { name, clear, code, current_date, date_candidates, start, end } =
    stockInfo;

  console.log(error, isError, error?.response?.status);
  const isTooMuchData =
    error?.message === "Too much data" ||
    isError ||
    error?.response?.status === 413;

  return (
    <ChartAreaWrapper>
      <AlphaNoteStockInfo
        name={name}
        code={code}
        currentDate={current_date}
        dateCandidates={date_candidates}
        start={start}
        end={end}
        clear={clear}
        pnl={data?.info?.pnl}
        seed={data?.info?.max_amt_buy}
        ret={data?.info?.ret}
      />
      <div className="chart-section-day">
        <div className="chart-name">일봉</div>
        <div className="chart">
          <AlphaChart
            key={"day"}
            chartData={data?.day_chart}
            markersData={data?.day_chart?.markers}
            name={unescapeHtml(data?.name) || ""}
          />
        </div>
      </div>

      <div className="chart-section-min">
        <div className="chart-name">분봉</div>
        {isTooMuchData ? (
          <div className="flex items-center justify-center h-64 text-gray-500">
            한달이 넘는 기간의 데이터는 분봉 차트를 제공하지 않습니다
          </div>
        ) : (
          <>
            <div className="cart-toggle">
              <ToggleComponet
                onClick={(type) => {
                  setCurrentType(type);
                  refetch({
                    note_id: { ...data?.note_id, unit: currentType[0] },
                  });
                }}
                labels={[
                  { name: "1분", type: "1", value: "1" },
                  { name: "3분", type: "3", value: "3" },
                  { name: "5분", type: "5", value: "5" },
                  { name: "30분", type: "30", value: "30" },
                ]}
                initType={currentType}
              />
            </div>
            <div className="chart">
              {isLoading ? (
                <div className="chart-loading"></div>
              ) : (
                <AlphaChart
                  key={"min"}
                  chartData={minChartData?.min_chart}
                  markersData={minChartData?.min_chart?.markers}
                  mode={"extra"}
                  name={unescapeHtml(data?.name) || ""}
                />
              )}
            </div>
          </>
        )}
      </div>
      <HistoryArea reportsData={data.trs} />
    </ChartAreaWrapper>
  );
};
