import { useState, useEffect } from "react";
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import {
  convertToNearWon,
  convertToPercentage,
  convertToPnlRatio,
} from "utils/utils";

const CardWrapper = styled.div`
  font-family: Pretendard;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;

  .ts-card-title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    .ts-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.63px;
      color: #141414;
    }
  }

  .ts-value {
    margin-top: 10px;
    font-size: 28px;
    font-weight: 600;
    line-height: 33.41px;
    text-align: left;
    display: flex;
    gap: 4px;
  }

  @media (max-width: 1200px) {
    padding: 16px;

    .ts-value {
      font-size: 24px;
      line-height: 28px;
    }
  }
`;

const CustomBarShape = (props) => {
  const { x, y, width, height, fill } = props;
  return (
    <rect x={x} y={y} width={width} height={height} fill={fill} rx="2" ry="2" />
  );
};

export const TradingStyleCard = ({
  data,
  title = "",
  type = "pie",
  styleValue = "",
  value = "",
  formatter = (item) => item,
}) => {
  const getChartDimensions = () => {
    const isMobile = window.innerWidth <= 1200;
    return {
      pieSize: isMobile ? 150 : 182,
      innerRadius: isMobile ? 60 : 72,
      outerRadius: isMobile ? 75 : 91,
    };
  };

  const [chartDimensions, setChartDimensions] = useState(getChartDimensions());

  // Window resize handler
  useEffect(() => {
    const handleResize = () => {
      setChartDimensions(getChartDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <CardWrapper>
      <div className="ts-card-title-section">
        <div className="ts-title">{title}</div>
      </div>
      <div className="ts-value">
        <span>{styleValue}</span>
        <span>{value}</span>
      </div>
      {type === "pie" && (
        <TradingStylPieGraph data={data} dimensions={chartDimensions} />
      )}
      {type === "bar" && (
        <TradingStyleBarGraph data={data} formatter={formatter} />
      )}
      {(type === "pnl_ratio" || type === "pnl") && (
        <StylePnlCard currentType={type} data={data} formatter={formatter} />
      )}
    </CardWrapper>
  );
};

const TradingStyleCardWrapper = styled.div`
  border-radius: 8px;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: left;

  padding: 0 20px;

  .title {
    color: #141414;
  }

  .profit {
    font-weight: 600;
    color: #000000;
    font-size: 28px;
    line-height: 33.41px;
    padding: 10px 0 22px;
  }

  .toggle {
    width: fit-content;
  }
`;

const PieGraphWrapper = styled.div`
  height: 200px;
  display: flex;
  margin-top: 17px;
  width: 100%;

  @media (max-width: 1200px) {
    height: 180px;
    margin-top: 12px;
  }
`;

const piedata = [
  { name: "스캘핑", value: 321 },
  { name: "데이", value: 224 },
  { name: "스윙", value: 171 },
];
const COLORS = ["#FF3D6C", "#73D6B7", "#8268EB"];

const PieDataWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 14px;

  @media (max-width: 1200px) {
    margin-top: 16px;
    gap: 10px;
  }
`;

const PieData = styled.div`
  display: flex;
  align-items: center;

  .block {
    width: 8px;
    height: 8px;
    background: ${(props) => props.color};
    align-self: center;
    margin-right: 5px;
  }

  .name {
    flex-shrink: 0;
  }
  .time {
    margin-left: auto;
    color: #969799;

    font-size: 15px;
    font-weight: 400;
    line-height: 17.9px;
    text-align: right;
  }
  .percent {
    width: 61px;
    font-size: 15px;
    font-weight: 500;
    line-height: 17.9px;
    text-align: right;
    margin-left: 13px;
  }

  @media (max-width: 1200px) {
    .time,
    .percent {
      font-size: 13px;
      line-height: 15.5px;
    }

    .percent {
      width: 50px;
      margin-left: 8px;
    }
  }
`;

const TradingStylPieGraph = ({ data, dimensions }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
    } = props;

    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fontSize={window.innerWidth <= 1200 ? "12px" : "14px"}
        >
          {"매매 비중"}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius * 1.04}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  const trxtotal = data.n_tr.scalping + data.n_tr.day + data.n_tr.swing;
  const trxData = [
    { name: "스캘핑", value: data.n_tr.scalping || 0 },
    { name: "데이", value: data.n_tr.day || 0 },
    { name: "스윙", value: data.n_tr.swing || 0 },
  ];

  return (
    <>
      <PieGraphWrapper>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={trxData}
              cx="50%"
              cy="50%"
              innerRadius={dimensions.innerRadius}
              outerRadius={dimensions.outerRadius}
              fill="#8884d8"
              paddingAngle={1}
              dataKey="value"
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              onMouseEnter={(_, index) => setActiveIndex(index)}
            >
              {trxData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip cursor={{ fill: "transparent" }} />
          </PieChart>
        </ResponsiveContainer>
      </PieGraphWrapper>
      <PieDataWrapper>
        {trxData.map((item, index) => (
          <PieData key={index} color={COLORS[index % COLORS.length]}>
            <div className="block" />
            <div className="name">{item.name}</div>
            <div className="time">{item.value}회</div>
            <div className="percent">
              {convertToPercentage((item.value / trxtotal) * 100)}
            </div>
          </PieData>
        ))}
      </PieDataWrapper>
    </>
  );
};

const StyleCardWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ebedf5;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: left;

  display: flex;
  flex-direction: row;
`;

const StyleBarCard = styled.div`
  width: 100%;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: ;

  .chart {
    height: 312px;
    margin-top: 24px;
  }

  .title {
    color: #141414;
  }

  .value {
    font-weight: 600;
    color: #000000;
    font-size: 28px;
    line-height: 33.41px;
    padding: 10px 0 18px;
  }

  .toggle {
    width: fit-content;
  }

  &:first-child {
    border-right: 1px dashed #ebedf5;
  }

  @media (max-width: 1200px) {
    .chart {
      height: 250px;
      margin-top: 16px;
    }

    .value {
      font-size: 24px;
      line-height: 28px;
      padding: 8px 0 14px;
    }
  }
`;

const TradingStyleBarGraph = ({
  data,
  formatter = (item) => {
    return item;
  },
}) => {
  const graphData = [
    {
      name: "스켈핑",
      value: data.scalpingData,
    },
    {
      name: "데이",
      value: data.dayData,
    },
    {
      name: "스윙",
      value: data.swingData,
    },
  ];

  return (
    <StyleBarCard>
      <div className="chart">
        <ResponsiveContainer>
          <BarChart data={graphData}>
            <XAxis dataKey="name" />
            <YAxis padding={{ top: 10 }} width={64} tickFormatter={formatter} />
            {/* <Tooltip
              cursor={{ fill: "transparent" }}
              formatter={(item) => formatter(item)}
            /> */}
            <Bar
              dataKey={"value"}
              fill="#8884d8"
              barSize={16}
              // shape={<CustomBarShape />}
            >
              {graphData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
              <LabelList
                dataKey="value"
                formatter={formatter}
                content={({ x, y, width, height, value, index }) => {
                  return (
                    <text
                      x={x + width / 2}
                      y={(value >= 0 ? y : y + height) - 4}
                      fill={COLORS[index % COLORS.length]}
                      textAnchor="middle"
                    >
                      {formatter(value)}
                    </text>
                  );
                }}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </StyleBarCard>
  );
};

const StylePnlCard = ({
  data,
  currentType = "pnl_ratio",
  formatter = (item) => item,
}) => {
  // const [currentType, setCurrentType] = useState("pnl_ratio");

  const { scalping: scalpingData, day: dayData, swing: swingData } = data || {};
  const pnlRatioData = [
    {
      name: "스켈핑",
      pnl_ratio: scalpingData.pnl_ratio,
      avg_ret_win: scalpingData.avg_ret_win,
      avg_ret_lose: scalpingData.avg_ret_lose,
    },
    {
      name: "데이",
      pnl_ratio: dayData.pnl_ratio,
      avg_ret_win: dayData.avg_ret_win,
      avg_ret_lose: dayData.avg_ret_lose,
    },
    {
      name: "스윙",
      pnl_ratio: swingData.pnl_ratio,
      avg_ret_win: swingData.avg_ret_win,
      avg_ret_lose: swingData.avg_ret_lose,
    },
  ];

  const max_pnlRatio = pnlRatioData.reduce((prev, current) =>
    prev.pnl_ratio > current.pnl_ratio ? prev : current
  );

  const pnlData = [
    {
      name: "스켈핑",
      pnl: scalpingData.pnl,
      pnl_win: scalpingData.pnl_win,
      pnl_lose: scalpingData.pnl_lose,
    },
    {
      name: "데이",
      pnl: dayData.pnl,
      pnl_win: dayData.pnl_win,
      pnl_lose: dayData.pnl_lose,
    },
    {
      name: "스윙",
      pnl: swingData.pnl,
      pnl_win: swingData.pnl_win,
      pnl_lose: swingData.pnl_lose,
    },
  ];

  const max_pnl = pnlData.reduce((prev, current) =>
    prev.pnl > current.pnl ? prev : current
  );

  const StyleValue = {
    pnl_ratio: max_pnlRatio.name + " " + convertToPnlRatio(max_pnl.pnl_ratio),
    pnl: max_pnl.name + " " + convertToNearWon(max_pnl.pnl),
  };

  const StyleData = {
    pnl_ratio: pnlRatioData,
    pnl: pnlData,
  };

  const StyleKey = {
    pnl_ratio: ["avg_ret_win", "avg_ret_lose"],
    pnl: ["pnl_win", "pnl_lose"],
  };

  const LEGEND = {
    avg_ret_win: "익절률",
    avg_ret_lose: "손절률",
    pnl_win: "익절금",
    pnl_lose: "손절금",
  };

  return (
    <StyleBarCard>
      <div className="chart">
        <ResponsiveContainer>
          <BarChart data={StyleData[currentType]}>
            <XAxis dataKey="name" />
            <YAxis tickFormatter={formatter} width={64} padding={{ top: 18 }} />
            <Tooltip
              cursor={{ fill: "transparent" }}
              formatter={(value, name, props) => [
                formatter(value),
                LEGEND[name],
              ]}
            />
            <Legend
              verticalAlign="top"
              align="right"
              formatter={(value, entry, index) => (
                <span style={{ color: "#000000" }}>{LEGEND[value]}</span>
              )}
            />
            <Bar
              dataKey={StyleKey[currentType][0]}
              fill="#FF3D6C"
              barSize={16}
              // shape={<CustomBarShape />}
            >
              <LabelList
                dataKey={StyleKey[currentType][0]}
                position="top"
                content={({ x, y, width, value, index }) => {
                  const otherBarValue =
                    StyleData[currentType][index][StyleKey[currentType][1]];
                  if (value >= otherBarValue) {
                    return (
                      <text
                        x={x + width / 2}
                        y={y - 4}
                        fill="#000"
                        textAnchor="middle"
                      >
                        {currentType === "pnl_ratio"
                          ? convertToPnlRatio(
                              StyleData[currentType][index]?.pnl_ratio
                            )
                          : formatter(value)}
                      </text>
                    );
                  }
                  return null;
                }}
              />
            </Bar>
            <Bar
              dataKey={StyleKey[currentType][1]}
              fill="#FFD5E0"
              barSize={16}
              shape={<CustomBarShape />}
            >
              <LabelList
                dataKey={StyleKey[currentType][1]}
                position="top"
                content={({ x, y, width, value, index }) => {
                  const otherBarValue =
                    StyleData[currentType][index][StyleKey[currentType][0]];
                  if (value > otherBarValue) {
                    return (
                      <text
                        x={x + width / 2}
                        y={y - 4}
                        fill="#000"
                        textAnchor="middle"
                      >
                        {currentType === "pnl_ratio"
                          ? convertToPnlRatio(
                              StyleData[currentType][index]?.pnl_ratio
                            )
                          : formatter(value)}
                      </text>
                    );
                  }
                  return null;
                }}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </StyleBarCard>
  );
};
