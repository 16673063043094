import React from "react";
// components/Toast/Toast.styles.js
import styled, { keyframes } from "styled-components";

export const slideIn = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const slideOut = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
`;

export const ToastContainer = styled.div`
  position: fixed;
  top: 24px;
  right: 24px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ToastItem = styled.div`
  padding: 14px 20px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.$type === "error" ? "#FF3D6C" : "#4C67FF"};
  color: white;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  animation: ${(props) => (props.$leaving ? slideOut : slideIn)} 0.3s
    ease-in-out forwards;
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: 1200px) {
    margin: 0 16px;
    font-size: 13px;
  }
`;

const Toast = ({ toasts }) => {
  return (
    <ToastContainer>
      {toasts.map((toast) => (
        <ToastItem key={toast.id} $type={toast.type} $leaving={toast.leaving}>
          {toast.message}
        </ToastItem>
      ))}
    </ToastContainer>
  );
};

export default Toast;
