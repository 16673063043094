import MyApexChart from "components/MyApexChart";
import StyledDatePicker from "components/StyledDatePicker";
import {
  useAlphaDetailHomeDailyGanttDateListQuery,
  useAlphaDetailHomeDailyGanttQuery,
} from "hooks/queries/useAlphaTraderDetailQuery";
import { useEffect, useState, useMemo } from "react";
import styled from "styled-components";

import { ReactComponent as ArrowLeft } from "assets/images/ico_arrow_left_s.svg";
import { ReactComponent as ArrowRight } from "assets/images/ico_arrow_right_s.svg";
import { convertToPercentage, convertToWon } from "utils/utils";

const CalendarButtonWrapper = styled.div`
  align-items: center;
  margin-left: 16px;
  border-collapse: collapse;
  display: flex;

  .left {
    border-radius: 4px 0 0 4px;
  }

  .right {
    border-radius: 0 4px 4px 0;
    border-left: 0;
  }
`;

const CalendarButton = styled.div`
  width: 26px;
  height: 24px;
  background: #ffffff;

  text-align: center;
  cursor: pointer;
  border: 1px solid #e7e9ef;

  &:hover {
    background: #f2f4f7;
  }

  &:active {
    background: #e7e9ef;
  }
`;

const SectionWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 16px;

  .gc-title-section {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 600;
    line-height: 23.87px;
    text-align: left;
    color: #141414;
    display: flex;
    justify-content: space-between;
  }

  .gc-card-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  @media (max-width: 1200px) {
    .gc-title-section {
      font-size: 18px;
      line-height: 21px;
    }
  }
`;

const ChartWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 24px;
  border: 1px solid #ebedf5;
  border-radius: 8px;
  box-shadow: 5px 5px 22px 0px #6574900f;

  .title {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 600;
    line-height: 23.87px;
    text-align: left;
    padding: 24px 24px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .react-datepicker-wrapper {
      padding: 0;
    }
  }

  .toggle {
    width: fit-content;
    padding: 0 24px;
  }

  .graph {
    flex: 1;
    min-height: 0;
  }

  .description {
    padding: 0 24px 28px;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.51px;
    text-align: left;
    color: #7d7e80;
    display: flex;
    gap: 16px;

    span {
      color: #141414;
      margin-left: 4px;
    }
  }

  @media (max-width: 1200px) {
    padding: 16px;

    .title {
      padding: 16px 16px 12px;
      font-size: 16px;
      line-height: 19px;
      flex-direction: row;
      gap: 12px;
    }

    .description {
      padding: 0 16px 20px;
      font-size: 12px;
      line-height: 14px;
      gap: 8px;
    }

    ${CalendarButtonWrapper} {
      margin-left: 0;
      justify-content: flex-end;
    }
  }
`;

const GanttChartSection = ({ cuid, title = "" }) => {
  const [validDates, setValidDates] = useState();
  const [currentDate, setCurrentDate] = useState();
  const [currentData, setCurrentData] = useState();

  const chartHeight = useMemo(() => {
    if (!currentData?.chart) return 340;

    const dataCount = Object.keys(currentData.chart).length;
    const baseHeight = 100;
    const heightPerItem = 50;
    const minHeight = 0;

    // 모바일에서는 더 작은 높이를 사용
    const isMobile = window.innerWidth <= 1200;
    const mobileHeightPerItem = 20; // 모바일에서는 아이템당 높이를 줄임

    const calculatedHeight =
      baseHeight + dataCount * (isMobile ? mobileHeightPerItem : heightPerItem);
    return Math.max(calculatedHeight, minHeight);
  }, [currentData]);

  const { data: dateList, isLoading: dateListLoading } =
    useAlphaDetailHomeDailyGanttDateListQuery({
      cuid,
    });

  const { data, isLoading, refetch } = useAlphaDetailHomeDailyGanttQuery({
    cuid,
    date: currentDate,
  });

  useEffect(() => {
    if (dateList) {
      const sele = dateList;
      setCurrentDate(sele[sele.length - 1]);
      setValidDates(sele);
    }
  }, [dateList, dateListLoading]);

  useEffect(() => {
    if (data) {
      const convertedData = data;
      setCurrentData(convertedData);
    }
  }, [isLoading, data]);

  const handleNextDate = () => {
    const index = validDates.findIndex(
      (date) => new Date(date).getTime() === new Date(currentDate).getTime()
    );
    if (index < validDates.length - 1) {
      setCurrentDate(validDates[index + 1]);
    }
  };

  const handlePrevDate = () => {
    const index = validDates.findIndex(
      (date) => new Date(date).getTime() === new Date(currentDate).getTime()
    );
    if (index > 0) {
      setCurrentDate(validDates[index - 1]);
    }
  };

  const handleClickDate = (newDate) => {
    const index = validDates.findIndex(
      (date) => new Date(date).getTime() === new Date(newDate).getTime()
    );

    if (index >= 0) {
      setCurrentDate(validDates[index]);
    }
  };

  return (
    <SectionWrapper>
      <div className="gc-title-section">
        <div className="gc-title">{title}</div>
        <div className="gc-button"></div>
      </div>
      <ChartWrapper>
        {!currentData ? (
          <></>
        ) : (
          <>
            <div className="title">
              <StyledDatePicker
                currentDate={currentDate}
                onDateSelect={handleClickDate}
                includeDates={validDates}
              />

              <CalendarButtonWrapper>
                <CalendarButton
                  className="left"
                  onClick={() => handlePrevDate()}
                >
                  <ArrowLeft />
                </CalendarButton>
                <CalendarButton
                  className="right"
                  onClick={() => handleNextDate()}
                >
                  <ArrowRight />
                </CalendarButton>
              </CalendarButtonWrapper>
            </div>

            <div className="description">
              <div>
                종목수<span>{currentData?.info?.n_stock}종목</span>
              </div>
              <div>
                수익금<span>{convertToWon(currentData?.info?.pnl)}</span>
              </div>
              <div>
                수익률<span>{convertToPercentage(currentData?.info?.ret)}</span>
              </div>
            </div>

            <div className="graph">
              <MyApexChart
                data={currentData?.chart}
                height={chartHeight}
                noteIdList={Object.values(currentData?.chart).map(
                  (item) => item.note_id
                )}
              />
            </div>
          </>
        )}
      </ChartWrapper>
    </SectionWrapper>
  );
};

export default GanttChartSection;
