import { sliderClasses } from "@mui/material";
import { ReactComponent as Complete } from "assets/images/ico_complete.svg";
import { Dot } from "components/AlphaStockTraderCard";
import AlphaStyleTag from "components/AlphaStyleTag";
import Dropdown from "components/DropDown";
import DropDownTradeStyle from "components/DropDownTradeStyle";
import { SvgStkImg } from "components/StockIcon";
import {
  useAlphaDetailStockInfoQuery,
  useAlphaDetailStockListQuery,
  useAlphaDetailStockNoteQuery,
} from "hooks/queries/useAlphaTraderDetailQuery";
import { useIntersectionObserver } from "hooks/useIntersectionObserver";
import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";
import { TRADE_STYLE_KTYPE } from "utils/consts";
import {
  addCommaToInt,
  convertToNearWon,
  convertToPercentage,
  formatSec,
} from "utils/utils";

const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
`;

const CompleteIcon = styled(Complete)`
  display: flex;
  justify-content: flex-end;
`;

const DetailNoteWrapper = styled.div`
  color: #141414;
  font-family: Pretendard;
  display: flex;
  flex-direction: row;
  gap: 27px;
  margin-bottom: 40px;

  .p {
    color: #ff2727;
  }

  .n {
    color: #4c67ff;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    position: relative;
    padding-bottom: 60px; // 토글 버튼 높이만큼 여백
  }
`;
const TitleWrapper = styled.div`
  color: #141414;
  font-family: Pretendard;
  display: flex;
  flex-direction: row;
  gap: 27px;

  .styles-module_tooltip__mnnfp {
    border-radius: 6px;
    padding: 12px;
  }
  .dn-title-summary-section {
    display: flex;
    flex-direction: row;
    align-items: end;

    .summary-icon {
      width: 48px;
      height: 48px;
      margin: 0 14px 12px 0;
    }
  }
  .dn-title-section {
    margin: 40px 0 12px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .dn-title {
      font-size: 22px;
      font-weight: 600;
      line-height: 23.87px;
      text-align: left;
      margin-bottom: 6px;
      display: flex;

      .tag {
        color: #7d7e80;
        font-family: Pretendard;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.32px;
        text-align: left;
        background: #f7f8fa;
        border-radius: 4px;
        border: 1px solid #dcdee3;
        padding: 4px 6px;
        margin: 0 0 0 6px;
      }
    }

    .dn-summary-title {
      font-size: 22px;
      font-weight: 600;
      line-height: 23.87px;
      text-align: left;
      margin-bottom: 8px;
    }

    .dn-info {
      font-family: Pretendard;
      font-size: 15px;
      font-weight: 400;
      line-height: 17.9px;
      display: flex;
      color: #afb0b2;
      gap: 20px;
    }
    .dn-value {
      color: #141414;
      margin-left: 6px;
    }

    .p {
      color: #ff2727;
    }

    .n {
      color: #4c67ff;
    }

    .title-date {
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.71px;
      text-align: center;

      color: #afb0b2;
      margin-left: 6px;
    }
  }

  .p {
    color: #ff2727;
  }

  .n {
    color: #4c67ff;
  }
`;

const SlideWrapper = styled.div`
  max-height: 700px;
  margin: 37px 0 12px;
  width: 260px;
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px 8px;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.32px;
  text-align: left;
  color: #000000;

  border: 1px solid #ebedf5;

  .time-info {
    color: #afb0b2;
    margin-bottom: 8px;
  }

  .dn-stocklist {
    margin: 10px 0;
    overflow-y: scroll;
    max-height: 567px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .drop-wrapper {
    display: flex;
    gap: 8px;
    padding: 8px 0 10px;
    z-index: 999;
  }

  .text-field {
    position: relative;
    svg {
      position: absolute;
      left: 12px;
      top: 12px;
    }
  }

  @media (max-width: 1200px) {
    width: 100%;
    margin: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    max-height: 50vh;
    border-radius: 16px 16px 0 0;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.1);
    transform: translateY(
      ${({ $isOpen }) => ($isOpen ? "0" : "calc(100% - 60px)")}
    );
    transition: transform 0.3s ease-in-out;
    padding-top: 0;

    .text-field {
      display: none;
    }

    .dn-stocklist {
      max-height: calc(50% - 100px);
    }
  }
`;

const StockListWrapper = styled.div`
  box-sizing: border-box;
  padding: 13px 12px;
  display: flex;
  align-items: center;
  border-radius: 6px;

  .sl-info {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
  }
  .sl-name {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
    text-align: left;
  }

  .sl-data {
    align-items: center;
    gap: 4px;
    display: flex;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.51px;
    text-align: left;
    color: #646566;
  }
  .sl-note {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.71px;
    text-align: right;
    color: #c8c9cc;
    margin-left: auto;
  }

  .p {
    color: #ff2727;
  }

  .n {
    color: #4c67ff;
  }

  &:hover {
    background: rgba(247, 248, 250, 0.8);
    cursor: pointer;
  }

  ${(props) =>
    props.$is_selected === "active"
      ? `
  background: #eff1f7;

  .sl-name {
    font-weight: 600;
    color: #000000;
  }

  .sl-note {
    color: #7d7e80;
  }
    `
      : ""};
`;

const TooltipWrapper = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  line-height: 15.51px;
  text-align: left;
  font-weight: 400;
  width: 156px;
  color: #ffffff;

  .tooltip-title {
    font-weight: 600;
    margin-bottom: 8px;
  }
  .tooltip-body {
    display: flex;
    gap: 4px;
    flex-direction: column;
  }
  .tooltip-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  a {
    color: #c8c9cc;
    text-decoration: underline;
    cursor: pointer;
  }
`;

// 토글 버튼 컴포넌트
const ToggleButton = styled.button`
  display: none;

  @media (max-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    background: #ffffff;
    border: none;
    border-radius: 16px 16px 0 0;
    cursor: pointer;
    position: relative;
    padding: 8px;
    gap: 8px;
  }
`;

// 수정된 ToggleArrow 컴포넌트
const ToggleArrow = ({ isOpen }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
      transition: "transform 0.3s ease",
    }}
  >
    <path
      d="M7 14L12 9L17 14"
      stroke="#4a90e2"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const StockListCard = ({ isSelected = false, stock, onClick, stocks = [] }) => {
  const { name, n_note, code, ret, pnl } = stock;
  return (
    <StockListWrapper
      key={name}
      onClick={() => onClick(stock)}
      $is_selected={isSelected ? "active" : ""}
      $is_total={stocks.size > 0 ? "total" : ""}
    >
      {stocks.length > 0 ? (
        // stocks.slice(0, 3).map((item) => {
        //   return <SvgStkImg code={item.code} name={item.name} size={16} />;
        // })
        <SvgStkImg code={code} name={name} size={16} />
      ) : (
        <SvgStkImg code={code} name={name} size={28} />
      )}

      <div className="sl-info">
        <div className="sl-name">{name}</div>
        {stocks.length === 0 && (
          <div className="sl-data">
            <span className={ret < 0 ? "n" : "p"}>
              {convertToPercentage(ret)}
            </span>
            <Dot color={"#C8C9CC"} />
            <span>{convertToNearWon(pnl)}</span>
          </div>
        )}
      </div>

      <div className="sl-note">{n_note}</div>
    </StockListWrapper>
  );
};

const NoteListTitle = ({ infoData, selectedStock = {} }) => {
  const isSelectedStock = selectedStock?.code && selectedStock?.code !== "";
  const data = isSelectedStock
    ? {
        est_seed: selectedStock.max_amt_buy,
        est_pnl: selectedStock.pnl,
        ret: selectedStock.ret,
      }
    : infoData;
  return (
    <TitleWrapper>
      <Tooltip anchorSelect=".my-anchor-element" clickable>
        <TooltipWrapper>
          <div className="tooltip-title">주도주 선정</div>
          <div className="tooltip-body">
            <div className="tooltip-item" key={1}>
              <a>{"2023년 12월 2일"}</a>
              <span>5위</span>
            </div>

            <div className="tooltip-item" key={1}>
              <a>{"2023년 12월 2일"}</a>
              <span>5위</span>
            </div>
          </div>
        </TooltipWrapper>
      </Tooltip>
      <div className="dn-title-summary-section">
        {isSelectedStock && (
          <div className="summary-icon">
            <SvgStkImg
              code={selectedStock?.code}
              name={selectedStock?.name || ""}
              size={48}
            />
          </div>
        )}
        <div className="dn-title-section">
          {isSelectedStock ? (
            <div className="dn-title">
              {selectedStock.name}
              {/* <div className="tag my-anchor-element">주도주 선정</div> */}
            </div>
          ) : (
            <div className="dn-summary-title">성과 요약</div>
          )}
          <div className="dn-info">
            <div className="dn-info-item">
              투자금
              <span className="dn-value">
                {data?.est_seed ? convertToNearWon(data.est_seed) : "-"}
              </span>
            </div>
            <div className="dn-info-item">
              수익금
              <span className="dn-value">
                {data?.est_pnl ? convertToNearWon(data?.est_pnl) : "-"}
              </span>
            </div>
            <div className="dn-info-item">
              수익률
              <span className={`dn-value ${data?.ret < 0 ? "n" : "p"}`}>
                {data?.ret ? convertToPercentage(data.ret) : "-"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </TitleWrapper>
  );
};

const BodyWrapper = styled.div`
  .b-title-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    align-items: end;
  }

  .b-totla {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
    text-align: left;

    span {
      font-weight: 600;
    }
  }
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  max-height: calc(100vh - 480px);
  overflow-y: auto;
  border-radius: 6px;

  @media (max-width: 1200px) {
    max-height: calc(100vh - 200px);
    margin-bottom: 20px;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  tbody {
    background-color: #f2f4f7;
  }

  @media (max-width: 1200px) {
    display: block;

    tbody {
      display: block;
    }
  }
`;

const TableHeader = styled.thead`
  position: sticky;
  top: 0;
  background-color: #f2f4f7;
  z-index: 1;
  color: #323233;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.32px;
  text-align: left;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const TableRow = styled.tr`
  display: flex;

  &:hover {
    background: rgba(247, 248, 250, 1);
    cursor: pointer;
  }

  @media (max-width: 1200px) {
    display: flex;
    padding: 8px 12px;
    border-bottom: 1px solid #ebedf5;
    align-items: center;
    height: 40px;
  }
`;

const TableCell = styled.td`
  cursor: pointer;
  width: ${({ $width }) => $width};
  height: 43px;
  box-sizing: border-box;
  color: ${({ $color }) => ($color ? $color : "#323233")};
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.51px;
  text-align: left;

  padding: ${({ $padding }) => ($padding ? $padding : "8")}px;
  text-align: left;
  display: flex;
  align-items: center;

  .stock-name {
    gap: 4px;
  }

  .s-name {
    margin-left: 4px;
  }
  .s-right {
    text-align: right;
    justify-content: flex-end;
  }
  .s-left {
    text-align: left;
  }

  justify-content: ${({ $align }) => $align};

  svg {
    margin-left: 3px;
  }

  @media (max-width: 1200px) {
    padding: 4px;
    height: 40px;
    font-size: 12px;

    &.stock-name {
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.date-cell {
      width: 100px;
      color: #646566;
    }

    &.style-cell {
      width: 40px;
    }

    &.return-cell {
      width: 50px;
      text-align: right;
    }

    &.profit-cell,
    &.trx-cell,
    &.hold-time-cell,
    &.investment-cell {
      display: none;
    }
  }
`;

const HTableCell = styled.td`
  width: ${({ $width }) => $width};
  height: 43px;
  box-sizing: border-box;
  padding: 8px;
  text-align: left;
  display: flex;
  flex-direction: row;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.32px;
  text-align: left;
  color: #323233;

  align-items: center;

  .s-name {
    margin-left: 4px;
  }

  .s-right {
    text-align: right;
    justify-content: flex-end;
  }
  .s-left {
    text-align: left;
  }

  justify-content: ${({ $align }) => $align};
`;

const SortButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  cursor: pointer;
`;

const SortButtonComponent = ({
  handleClick = () => {},
  sortType,
  priority,
  sortAsc,
}) => {
  return (
    <SortButtonWrapper onClick={() => handleClick(sortType)}>
      <SortArrow active={sortType === priority && sortAsc} />
      <SortArrow active={sortType === priority && !sortAsc} rotate={"down"} />
    </SortButtonWrapper>
  );
};

const DateDisplay = ({ item }) => {
  console.log(item);
  const formatDate = (date) => {
    if (!date) return "";

    if (window.innerWidth > 1200) {
      return date;
    }

    const shortDate = date.slice(5, 7) + date.slice(8, 10);
    const middlePart = date.slice(10, 13);
    const endPart = date.slice(18, 20) + date.slice(21, 23);

    return (
      <>
        {shortDate}
        {middlePart === " ~ " ? `-${endPart}` : ""}
      </>
    );
  };

  return (
    <DateContainer>
      {formatDate(item.date)}
      {item.clear && <CompleteIcon />}
    </DateContainer>
  );
};

const NoteListBody = ({ selectedStock, cuid }) => {
  const [sortAsc, setSortAsc] = useState(false);
  const [priority, setPriority] = useState("");
  const [position, setPosition] = useState(0);
  const [selectedStyle, setStyleFilter] = useState("전체 스타일");

  const {
    data: noteData,
    isLoading: noteLoading,
    fetchNextPage: noteFetch,
    hasNextPage,
  } = useAlphaDetailStockNoteQuery({
    cuid: cuid,
    code: selectedStock,
    sort_priority: priority,
    sort_asc: sortAsc,
    styler_filter:
      selectedStyle === "전체 스타일" ? "" : TRADE_STYLE_KTYPE[selectedStyle],
  });

  useEffect(() => {
    setSortAsc(false);
    setPriority("");
    setPosition(0);
    setStyleFilter("전체 스타일");
  }, [selectedStock]);

  const handleClick = (type) => {
    if (type === priority) {
      if (sortAsc) {
        setSortAsc(false);
        setPriority("");
      } else {
        setSortAsc(!sortAsc);
      }
    } else {
      setSortAsc(false);
      setPriority(type);
    }
  };

  const setTarget = useIntersectionObserver({
    hasNextPage: hasNextPage,
    fetchNextPage: noteFetch,
  });

  return (
    <BodyWrapper>
      <div className="b-title-section">
        <div className="b-totla">
          <span>총 {noteData ? noteData?.pages[0]?.n : "-"}</span>개
        </div>
        <DropDownTradeStyle
          options={["전체 스타일", "스켈핑", "데이", "스윙"]}
          selected={selectedStyle}
          onClick={(style) => {
            setStyleFilter(style);
          }}
        />
      </div>
      <TableWrapper>
        <Table>
          <TableHeader>
            <TableRow key="top">
              <HTableCell $width="150px">
                종목
                <SortButtonComponent
                  priority={priority}
                  sortAsc={sortAsc}
                  sortType={"name"}
                  handleClick={handleClick}
                />
              </HTableCell>
              <HTableCell $width="220px">
                날짜
                <SortButtonComponent
                  priority={priority}
                  sortAsc={sortAsc}
                  sortType={"start"}
                  handleClick={handleClick}
                />
              </HTableCell>
              <HTableCell $width="50px" $align="flex-end">
                스타일
              </HTableCell>
              <HTableCell $width="70px" className="s-right" $align="flex-end">
                체결
                <SortButtonComponent
                  priority={priority}
                  sortAsc={sortAsc}
                  sortType={"n_trx"}
                  handleClick={handleClick}
                />
              </HTableCell>
              <HTableCell $width="80px" $align="flex-end">
                보유시간
                <SortButtonComponent
                  priority={priority}
                  sortAsc={sortAsc}
                  sortType={"avg_hold_time"}
                  handleClick={handleClick}
                />
              </HTableCell>
              <HTableCell $width="90px" $align="flex-end">
                투자금
                <SortButtonComponent
                  priority={priority}
                  sortAsc={sortAsc}
                  sortType={"max_amt_buy"}
                  handleClick={handleClick}
                />
              </HTableCell>
              <HTableCell $width="90px" $align="flex-end ">
                수익금
                <SortButtonComponent
                  priority={priority}
                  sortAsc={sortAsc}
                  sortType={"pnl"}
                  handleClick={handleClick}
                />
              </HTableCell>
              <HTableCell $align="flex-end " $width="90px">
                수익률
                <SortButtonComponent
                  priority={priority}
                  sortAsc={sortAsc}
                  sortType={"ret"}
                  handleClick={handleClick}
                />
              </HTableCell>
            </TableRow>
          </TableHeader>
          <tbody>
            {noteData?.pages?.map((page, pindex) =>
              page.note?.map((item, index) => (
                <TableRow
                  ref={
                    pindex === noteData?.pages?.length - 1 &&
                    index === page.note?.length - 1
                      ? setTarget
                      : null
                  }
                  key={index}
                  onClick={() => {
                    window.open(
                      `/note?cuid=${item.cuid}&code=${item.code}&date=${item.date}`,
                      "newwindow",
                      "width=1300, height=1200"
                    );
                  }}
                  className="stock-mini-card"
                >
                  <TableCell $width="150px" className="stock-name">
                    <SvgStkImg code={item.code} name={item.name} size={16} />
                    <div className="s-name">{item.name}</div>
                  </TableCell>
                  <TableCell className="date-cell" $width="220px">
                    <DateDisplay item={item} />
                  </TableCell>
                  <TableCell
                    className="style-cell"
                    $width="50px"
                    $align="flex-end"
                    $padding="0"
                  >
                    <AlphaStyleTag type={item.style}>
                      {item.style}
                    </AlphaStyleTag>
                  </TableCell>
                  <TableCell
                    className="trx-cell"
                    $width="70px"
                    $align="flex-end "
                  >
                    {addCommaToInt(item.n_trx) + "건"}
                  </TableCell>
                  <TableCell
                    className="hold-time-cell"
                    $width="80px"
                    $align="flex-end "
                  >
                    {formatSec(item.avg_hold_time)}
                  </TableCell>
                  <TableCell
                    className="investment-cell"
                    $width="90px"
                    $align="flex-end "
                  >
                    {convertToNearWon(item.max_amt_buy)}
                  </TableCell>
                  <TableCell
                    className="profit-cell"
                    $width="90px"
                    $align="flex-end "
                  >
                    {convertToNearWon(item.pnl)}
                  </TableCell>
                  <TableCell
                    className="return-cell"
                    $width="90px"
                    $align="flex-end "
                    $color={item.ret >= 0 ? "#ff2727" : "#4c67ff"}
                  >
                    {convertToPercentage(item.ret)}
                  </TableCell>
                </TableRow>
              ))
            )}
          </tbody>
        </Table>
      </TableWrapper>
    </BodyWrapper>
  );
};

const FilterTextField = styled.input`
  width: 100%;
  height: 40px;
  padding: 12px 14px 11px 34px;
  border: 1px solid #ebedf5;
  border-radius: 6px;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: left;

  color: #141414;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #141414;
  }
`;

const SearchIcon = ({ focus = false }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7 7.2002C11.7 9.68548 9.68529 11.7002 7.20001 11.7002C4.71473 11.7002 2.70001 9.68548 2.70001 7.2002C2.70001 4.71491 4.71473 2.7002 7.20001 2.7002C9.68529 2.7002 11.7 4.71491 11.7 7.2002ZM10.6495 12.1101C9.67367 12.7969 8.48394 13.2002 7.20001 13.2002C3.8863 13.2002 1.20001 10.5139 1.20001 7.2002C1.20001 3.88649 3.8863 1.2002 7.20001 1.2002C10.5137 1.2002 13.2 3.88649 13.2 7.2002C13.2 8.68884 12.6579 10.0509 11.7603 11.0996L14.8901 14.2295C15.183 14.5224 15.183 14.9972 14.8901 15.2901C14.5972 15.583 14.1224 15.583 13.8295 15.2901L10.6495 12.1101Z"
      fill={focus ? "#141414" : "#C8C9CC"}
    />
  </svg>
);

const SortArrow = ({ active = false, rotate = "up" }) => (
  <svg
    width="7"
    height="6"
    viewBox="0 0 7 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: rotate === "up" ? "rotate(0deg)" : "rotate(180deg)" }}
  >
    <path
      d="M3.09852 0.540851C3.29843 0.271544 3.70157 0.271544 3.90148 0.540851L6.80474 4.45198C7.04964 4.7819 6.81415 5.25 6.40326 5.25H0.596739C0.185853 5.25 -0.0496422 4.7819 0.195262 4.45198L3.09852 0.540851Z"
      fill={active ? "#282E34" : "#C8C9CC"}
    />
  </svg>
);

const AlphaDetailNote = ({ cuid, lastUpdate = "", isHold = false }) => {
  const [filterText, setFilterText] = useState("");
  const [alphaNoteList, setAlphaNoteList] = useState([]);
  const [filteredStocks, setFilteredStocks] = useState([]);
  const [filterFocus, setFilterFocus] = useState(false);
  const [selectedStock, setSelectedStock] = useState({ code: "" });
  const [noteListFitlerType, setNoteListFilterType] = useState("전체종목");
  const [noteListOrder, setNoteListOrder] = useState("수익률순");
  const [isSlideOpen, setIsSlideOpen] = useState(false);

  const handleToggle = () => {
    setIsSlideOpen(!isSlideOpen);
  };

  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  const {
    data: infoData,
    isLoading: infoLoading,
    refetch: infoRefetch,
  } = useAlphaDetailStockInfoQuery({
    cuid: cuid,
  });

  const {
    data: listData,
    isLoading: listLoading,
    refetch: listRefetch,
  } = useAlphaDetailStockListQuery({
    cuid: cuid,
  });

  const filterStocks = (ftext, listData) => {
    return ftext !== ""
      ? listData.filter(
          (item) =>
            item.name.toLowerCase().includes(ftext.toLowerCase()) === true
        )
      : listData;
  };

  useEffect(() => {
    if (isHold) {
      setNoteListFilterType("보유종목");
    }
  }, [isHold]);

  useEffect(() => {
    if (noteListFitlerType === "전체종목") {
      setAlphaNoteList(listData?.stock?.complete);
      setSelectedStock("");
    }
    if (noteListFitlerType === "보유종목") {
      setAlphaNoteList(listData?.stock?.holding);
      setSelectedStock("");
    }
  }, [noteListFitlerType, listData]);

  function compPnl(a, b) {
    return parseFloat(b.pnl) - parseFloat(a.pnl);
  }

  function compRet(a, b) {
    return parseFloat(b.ret) - parseFloat(a.ret);
  }
  function compName(a, b) {
    return a.name.localeCompare(b.name);
  }
  function compDate(a, b) {
    return b.last_update.localeCompare(a.last_update);
  }
  function compRetR(a, b) {
    return parseFloat(a.ret) - parseFloat(b.ret);
  }
  function compPnlR(a, b) {
    return parseFloat(a.pnl) - parseFloat(b.pnl);
  }

  useEffect(() => {
    const fs =
      filterStocks(filterText, alphaNoteList)?.sort(
        noteListOrder === "수익률순"
          ? compRet
          : noteListOrder === "수익금순"
          ? compPnl
          : noteListOrder === "최신순"
          ? compDate
          : noteListOrder === "가나다순"
          ? compName
          : noteListOrder === "수익률역순"
          ? compRetR
          : noteListOrder === "수익금역순"
          ? compPnlR
          : null
      ) || [];

    setFilteredStocks([...fs]);
  }, [alphaNoteList, noteListOrder, filterText]);

  return (
    <DetailNoteWrapper>
      <SlideWrapper $isOpen={isSlideOpen}>
        <ToggleButton onClick={handleToggle}>
          종목 필터 <ToggleArrow isOpen={isSlideOpen} />
        </ToggleButton>
        <div className="time-info">
          {listData?.stock?.last_update || lastUpdate} 기준
        </div>
        <div className="drop-wrapper">
          <Dropdown
            options={["전체종목", "보유종목"]}
            onClick={setNoteListFilterType}
            selected={noteListFitlerType}
          />
          <Dropdown
            options={[
              "수익률순",
              "수익금순",
              "최신순",
              "가나다순",
              "수익률역순",
              "수익금역순",
            ]}
            onClick={setNoteListOrder}
            selected={noteListOrder}
          />
        </div>

        <div className="text-field">
          <FilterTextField
            type="text"
            placeholder="종목을 검색하세요."
            value={filterText}
            onChange={handleFilterChange}
            onFocus={() => setFilterFocus(true)}
            onBlur={() => setFilterFocus(false)}
          />
          <SearchIcon className="search-icon" focus={filterFocus} />
        </div>
        <div className="dn-stocklist">
          <StockListCard
            stock={{
              name: "알파노트 모아 보기",
              code: "",
              n_note: listData?.stock?.n_note,
            }}
            key={"total"}
            onClick={setSelectedStock}
            isSelected={selectedStock === "" || selectedStock?.code === ""}
            stocks={listData?.stock?.complete}
          />

          {filteredStocks?.map((item) => {
            return (
              <StockListCard
                stock={item}
                key={item.name}
                onClick={setSelectedStock}
                isSelected={selectedStock?.code === item.code}
              />
            );
          })}
        </div>
      </SlideWrapper>
      <div className="body-section">
        <NoteListTitle infoData={infoData} selectedStock={selectedStock} />
        <NoteListBody selectedStock={selectedStock?.code || ""} cuid={cuid} />
      </div>
    </DetailNoteWrapper>
  );
};

export default AlphaDetailNote;
