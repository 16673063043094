import React, { useRef, useState } from "react";
import styled from "styled-components";
import useAuth from "utils/auth";
import {
  useGetPosts,
  useCreatePost,
  useDeletePost,
  useLikePost,
  useUpdatePost,
} from "hooks/queries/useBoardQuery";
import { useEffect } from "react";
import { formatTimeAgo } from "utils/utils";
import LikeIcon from "assets/images/ico_like.png";
import LikeActiveIcon from "assets/images/ico_like_avtive.png";

const POSTS_PER_PAGE = 10;
const MAX_CONTENT_LENGTH = 50;

const EditButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 0.5rem;

  @media (max-width: 1200px) {
    margin-top: 0.75rem;
  }
`;

const EditButton = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s;

  ${(props) =>
    props.$cancel &&
    `
    background: #F9FAFB;
    color: #6B7280;
    
    &:hover {
      background: #F3F4F6;
      color: #374151;
    }
  `}

  ${(props) =>
    props.$confirm &&
    `
    background: #2563EB;
    color: white;
    
    &:hover {
      background: #1D4ED8;
    }
  `}

  &:hover {
    transform: scale(1.02);
  }
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  gap: 0.75rem;
  align-items: flex-start;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
  margin-bottom: 4rem;

  @media (max-width: 1200px) {
    padding: 0 0.5rem;
  }
`;

const BoardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin: 0 auto;
`;

const Card = styled.div`
  background: white;
  border-radius: 0.5rem;
  border: 1px solid #f1f1f1;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  width: 100%;
  overflow: hidden;
`;

const CardContent = styled.div`
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

const LeftSection = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
  min-width: 0;
  width: 100%;
`;

const ProfileInfo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  width: 100%;
  min-width: 0;
`;

const ProfileImage = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 2.5rem;
  height: 2.5rem;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
`;

const ContentSection = styled.div`
  flex: 1;
  min-width: 0;
  overflow: hidden;
  width: 100%;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.25rem;
`;

const Username = styled.span`
  font-size: 0.875rem;
  font-weight: 600;
  color: #111827;
`;

const PostDate = styled.span`
  font-size: 0.75rem;
  color: #9ca3af;
`;

const PostText = styled.div`
  color: #4b5563;
  font-size: 0.875rem;
  width: 100%;
  overflow: hidden;
  word-wrap: break-word;
  text-align: left;

  .content {
    ${(props) =>
      !props.$isExpanded
        ? `
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `
        : `
      white-space: pre-wrap;
    `}
  }

  .toggle-button {
    color: #3b82f6;
    font-size: 0.75rem;
    margin-left: 0.5rem;
    cursor: pointer;
  }

  @media (max-width: 1200px) {
    font-size: 0.8125rem;
    width: 100%;
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  flex-shrink: 0;
  margin-left: auto;

  @media (max-width: 1200px) {
    margin-left: 0;
    width: 100%;
    justify-content: flex-end;
    padding-left: 3.25rem; // ProfileImage width + gap
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  padding: 0;
  color: #6b7280;
  border-radius: 0.5rem;
  transition: all 0.2s;
  background: ${(props) => (props.$danger ? "#FEF2F2" : "#F9FAFB")};

  &:hover {
    background: ${(props) => (props.$danger ? "#FEE2E2" : "#EFF6FF")};
    color: ${(props) => (props.$danger ? "#DC2626" : "#2563EB")};
    transform: scale(1.05);
  }
`;

const LikeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.375rem;
  height: 2.25rem;
  padding: 0 1rem;
  border-radius: 0.75rem;
  transition: all 0.2s;
  white-space: nowrap;
  background: ${(props) => (props.$isLiked ? "#FEE2E2" : "#F9FAFB")};
  color: ${(props) => (props.$isLiked ? "#DC2626" : "#6B7280")};
  font-weight: 500;

  &:hover:not(:disabled) {
    background: ${(props) => (props.$isLiked ? "#FECACA" : "#F3F4F6")};
    transform: scale(1.05);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  img {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }
`;
const TextArea = styled.textarea`
  width: 80%;
  height: 2.5rem;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  resize: none;
  min-height: 2.5rem;
  background: #f9fafb;
  border: 1px solid #e5e7eb;
  font-size: 0.875rem;
  color: #374151;
  transition: all 0.2s;

  @media (max-width: 1200px) {
    width: 100%;
  }

  &:focus {
    outline: none;
    border-color: #3b82f6;
  }

  &:disabled {
    background: #f3f4f6;
    cursor: not-allowed;
  }
`;

const PageButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2.25rem;
  height: 2.25rem;
  padding: 0 0.75rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s;
  background: ${(props) => (props.$isActive ? "#2563EB" : "#F9FAFB")};
  color: ${(props) => (props.$isActive ? "white" : "#4B5563")};

  @media (max-width: 1200px) {
    min-width: 2rem;
    height: 2rem;
    padding: 0 0.5rem;
    font-size: 0.8125rem;
  }

  &:hover:not(:disabled) {
    background: ${(props) => (props.$isActive ? "#1D4ED8" : "#F3F4F6")};
    transform: scale(1.05);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem; // 하단 여백 추가
  gap: 0.5rem;
  width: 100%;

  @media (max-width: 1200px) {
    gap: 0.25rem;
  }
`;

// ShareButton도 모던하게 업데이트
const ShareButton = styled.button`
  width: calc(20% - 0.75rem);
  height: 2.5rem;
  padding: 0 1rem;
  background: #2563eb;
  color: white;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 0.75rem;
  white-space: nowrap;
  transition: all 0.2s;

  @media (max-width: 1200px) {
    width: 100%;
  }

  &:hover:not(:disabled) {
    background: #1d4ed8;
    transform: scale(1.02);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Post = React.memo(
  ({ post, currentUser, onEdit, onDelete, onToggleLike }) => {
    const isAuthor = currentUser?.id === post.author_id;
    const [isExpanded, setIsExpanded] = useState(false);
    const shouldShowToggle = post.content.length > MAX_CONTENT_LENGTH;

    return (
      <Card>
        <CardContent>
          <LeftSection>
            <ProfileInfo>
              <ProfileImage>
                <img
                  src={`https://api.alphanote.io/static/uploads/profile/${post.author_id}.png`}
                  loading="lazy"
                  alt="Profile"
                />
              </ProfileImage>
              <ContentSection>
                <UserInfo>
                  <Username>{post.author}</Username>
                  <PostDate>{formatTimeAgo(post.date)}</PostDate>
                </UserInfo>
                <PostText $isExpanded={isExpanded}>
                  <span className="content">{post.content}</span>
                  {shouldShowToggle && (
                    <span
                      className="toggle-button"
                      onClick={() => setIsExpanded(!isExpanded)}
                    >
                      {isExpanded ? "접기" : "...더보기"}
                    </span>
                  )}
                </PostText>
              </ContentSection>
            </ProfileInfo>
          </LeftSection>

          <RightSection>
            {isAuthor && (
              <ActionButtons>
                <IconButton onClick={() => onEdit(post)}>✎</IconButton>
                <IconButton $danger onClick={() => onDelete(post)}>
                  ×
                </IconButton>
              </ActionButtons>
            )}
            <LikeButton
              $isLiked={post.is_liked}
              $isEnabled={!!currentUser}
              onClick={() => currentUser && onToggleLike(post)}
              disabled={!currentUser}
            >
              <img
                src={post.is_liked ? LikeActiveIcon : LikeIcon}
                alt="like"
                width="14"
                height="14"
              />
              <span>{post.n_like}</span>
            </LikeButton>
          </RightSection>
        </CardContent>
      </Card>
    );
  }
);
const BoardContainer = () => {
  const { isLoggedIn, currentUser } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [newPost, setNewPost] = useState({ content: "" });
  const [editingPost, setEditingPost] = useState(null);

  const adjustHeight = (element) => {
    // 현재 스크롤 높이와 클라이언트 높이를 비교
    const isOverflowing = element.scrollHeight > element.clientHeight;
    const hasLineBreak = element.value.includes("\n");

    // 넘치거나 줄바꿈이 있을 때만 높이 조절
    if (isOverflowing || hasLineBreak) {
      element.style.height = "auto"; // 높이를 초기화했다가
      element.style.height = `${element.scrollHeight}px`; // 스크롤 높이로 설정
    }
  };

  const handleEditTextAreaChange = (e) => {
    const textarea = e.target;
    adjustHeight(textarea); // 기존 adjustHeight 함수 재사용
    setEditingPost({
      ...editingPost,
      content: e.target.value,
    });
  };

  const handleTextAreaChange = (e) => {
    const textarea = e.target;
    adjustHeight(textarea);
    setNewPost({ content: e.target.value });
  };

  // 컴포넌트가 마운트될 때 초기 높이 설정
  useEffect(() => {
    const textarea = document.querySelector("textarea");
    if (textarea) {
      adjustHeight(textarea);
    }
  }, []);

  // useQuery의 refetch 설정
  const { data: postsData, isLoading } = useGetPosts(
    currentPage,
    POSTS_PER_PAGE
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newPost.content.trim()) return;

    if (!isLoggedIn) {
      alert("로그인이 필요합니다.");
      return;
    }

    createPostMutation.mutate(
      {
        content: newPost.content,
        nickname: currentUser?.nickname,
      },
      {
        onSuccess: () => {
          setNewPost({ content: "" });
          setCurrentPage(1);
          const textarea = document.querySelector("textarea");
          if (textarea) {
            textarea.style.height = "2.5rem"; // 초기 높이로 리셋
          }
        },
      }
    );
  };

  const createPostMutation = useCreatePost();
  const deletePostMutation = useDeletePost();
  const likePostMutation = useLikePost();
  const updatePostMutation = useUpdatePost();

  // 페이지 변경 시 데이터 갱신을 위한 의존성 추가
  useEffect(() => {
    // 페이지 변경 시 스크롤을 맨 위로
    window.scrollTo(0, 0);
  }, [currentPage]);

  const handleDelete = (post) => {
    if (!window.confirm("정말로 삭제하시겠습니까?")) return;
    deletePostMutation.mutate({ id: post._id });
  };

  const handleToggleLike = (post) => {
    if (!isLoggedIn) {
      alert("로그인이 필요합니다.");
      return;
    }
    likePostMutation.mutate(post._id);
  };

  const handleUpdate = (post) => {
    if (!post.content.trim()) return;
    updatePostMutation.mutate(
      { bid: post.bid, id: post._id, content: post.content },
      {
        onSuccess: () => {
          setEditingPost(null);
        },
      }
    );
  };

  const renderPagination = () => {
    if (!postsData?.total_pages) return null;

    return (
      <PaginationContainer>
        <PageButton
          onClick={() => setCurrentPage((prev) => Math.max(1, prev - 1))}
          disabled={currentPage === 1}
        >
          이전
        </PageButton>

        {[...Array(postsData.total_pages)].map((_, idx) => (
          <PageButton
            key={idx + 1}
            onClick={() => setCurrentPage(idx + 1)}
            $isActive={currentPage === idx + 1}
          >
            {idx + 1}
          </PageButton>
        ))}

        <PageButton
          onClick={() =>
            setCurrentPage((prev) => Math.min(postsData.total_pages, prev + 1))
          }
          disabled={currentPage === postsData.total_pages}
        >
          다음
        </PageButton>
      </PaginationContainer>
    );
  };

  return (
    <Container>
      <BoardWrapper>
        <Card>
          <CardContent style={{ padding: "1rem" }}>
            <Form onSubmit={handleSubmit}>
              <TextArea
                placeholder={
                  isLoggedIn
                    ? "오늘의 한줄평을 공유해주세요..."
                    : "로그인 후 작성할 수 있습니다"
                }
                value={newPost.content}
                onChange={handleTextAreaChange}
                disabled={!isLoggedIn}
              />
              <ShareButton
                type="submit"
                disabled={!isLoggedIn || !newPost.content.trim()}
              >
                공유하기
              </ShareButton>
            </Form>
          </CardContent>
        </Card>

        {postsData?.data.map((post) => (
          <div key={post._id}>
            {editingPost?._id === post._id ? (
              <Card>
                <CardContent>
                  <TextArea
                    value={editingPost.content}
                    onChange={handleEditTextAreaChange} // onChange 추가
                    ref={(el) => {
                      // 수정 모드로 전환될 때 TextArea 높이 초기화
                      if (el) adjustHeight(el);
                    }}
                  />
                  <EditButtonsContainer>
                    <EditButton $cancel onClick={() => setEditingPost(null)}>
                      취소
                    </EditButton>
                    <EditButton
                      $confirm
                      onClick={() => handleUpdate(editingPost)}
                    >
                      수정완료
                    </EditButton>
                  </EditButtonsContainer>
                </CardContent>
              </Card>
            ) : (
              <Post
                post={post}
                currentUser={currentUser}
                onEdit={setEditingPost}
                onDelete={handleDelete}
                onToggleLike={handleToggleLike}
              />
            )}
          </div>
        ))}
        {isLoading && (
          <div className="text-center py-4">
            <div className="inline-block animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
          </div>
        )}
        {renderPagination()}
      </BoardWrapper>
    </Container>
  );
};

export default BoardContainer;
