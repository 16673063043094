import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

const RoundCharacter = styled.span`
  min-width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;
  font-size: ${(props) => props.size / 2}px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  color: #fff;
  background-color: ${(props) => getColor(props.name)};
  display: flex;
`;

// 해시 함수
function hashString(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

// 해시 값을 색상으로 변환하는 함수
function intToRGB(i) {
  const shortened = i % 360;
  return `hsl(${shortened}, 50%, 70%)`;
}

// 해시 값을 사용하여 색상을 생성하는 함수
function getColor(name) {
  return intToRGB(hashString(name));
}

function SvgStkImg({ code, name, size }) {
  const [loadError, setLoadError] = useState(false);
  const svgPath = `/stk_img/${code}.svg`;

  const handleError = () => {
    setLoadError(true);
  };

  if (!size) size = 40;

  const memoizedSvg = useMemo(() => {
    return (
      <img
        src={svgPath}
        alt={`${name}(${code})`}
        onError={handleError}
        width={size}
        height={size}
      />
    );
  }, [code, name, size]); // 의존성 배열

  useEffect(() => {
    setLoadError(false);
  }, [code, name, size]);

  if (loadError) {
    return (
      <RoundCharacter name={name} size={size} title={`${name}(${code})`}>
        {name?.charAt(0)}
      </RoundCharacter>
    );
  } else {
    return memoizedSvg;
  }
}

export { SvgStkImg };
