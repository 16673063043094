import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  useCreateMemo,
  useDeleteMemo,
  useGetMemos,
  useUpdateMemo,
} from "hooks/queries/userAlphaNoteMemoQuery";

const MemoContainer = styled.div`
  position: fixed;
  right: ${(props) => (props.$isOpen ? "0" : "-400px")};
  top: 0;
  width: 400px;
  height: 100%;
  background: #ffffff;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  display: flex;
  flex-direction: column;
  z-index: 1000;

  @media (max-width: 1200px) {
    width: 100%;
    right: ${(props) => (props.$isOpen ? "0" : "-100%")};
  }
`;

const MemoHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  background: #fff;

  .title {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    font-family: Pretendard;
  }

  .close {
    padding: 8px 12px;
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
    font-size: 20px;

    &:hover {
      color: #333;
    }
  }
`;

const ToggleButton = styled.button`
  position: fixed;
  right: ${(props) => (props.$isOpen ? "420px" : "20px")};
  bottom: 20px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #4a90e2;
  color: white;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 1001;
  font-family: Pretendard;
  font-size: 14px;

  @media (max-width: 1200px) {
    right: ${(props) => (props.$isOpen ? "calc(100% + 20px)" : "20px")};
  }

  &:hover {
    background: #357abd;
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
`;

const MemoList = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 16px;

  @media (max-width: 1200px) {
    padding: 12px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #bbb;
  }
`;

const MemoItem = styled.div`
  padding: 12px;
  border: 1px solid #e0e0e0;
  margin-bottom: 8px;
  background: #f9fafc;
  border-radius: 4px;

  @media (max-width: 1200px) {
    padding: 16px;
    margin-bottom: 12px;
  }

  .memo-text {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 8px;
    font-family: Pretendard;
    white-space: pre-wrap;
    word-break: break-all;

    @media (max-width: 1200px) {
      font-size: 15px;
      line-height: 1.6;
    }
  }

  .memo-time {
    font-size: 12px;
    color: #666;
    font-family: Pretendard;

    @media (max-width: 1200px) {
      font-size: 13px;
    }
  }
`;

const InputWrapper = styled.form`
  display: flex;
  flex-direction: column;/
  padding: 16px;
  background: #fff;
  border-top: 1px solid #e0e0e0;
  z-index: 1001;

  @media (max-width: 1200px) {
    padding: 12px;
    padding-bottom: calc(12px + constant(safe-area-inset-bottom)); // iOS
    padding-bottom: calc(12px + env(safe-area-inset-bottom)); // iOS
  bottom: 0;
  }
`;

const MemoInput = styled.textarea`
  width: 100%;
  height: 80px;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  resize: none;
  font-size: 14px;
  margin-bottom: 8px;
  font-family: Pretendard;

  @media (max-width: 1200px) {
    font-size: 16px;
    padding: 16px;
    height: 100px;
  }

  &:focus {
    outline: none;
    border-color: #4a90e2;
    background: #f9fafc;
  }

  &::placeholder {
    color: #999;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;

  @media (max-width: 1200px) {
    gap: 12px;
  }
`;

const SubmitButton = styled.button`
  padding: 8px 16px;
  background: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  font-family: Pretendard;

  @media (max-width: 1200px) {
    padding: 12px 20px;
    font-size: 15px;
    flex: ${(props) => (props.$fullWidth ? "1" : "initial")};
  }

  &:hover {
    background: #357abd;
  }

  &:active {
    background: #2b62a3;
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const MemoItemActions = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
  justify-content: flex-end;

  @media (max-width: 1200px) {
    gap: 12px;
    margin-top: 12px;
  }
`;

const ActionButton = styled.button`
  padding: 4px 12px;
  font-size: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: ${(props) => (props.isPrimary ? "#4a90e2" : "#e0e0e0")};
  color: ${(props) => (props.isPrimary ? "white" : "#333")};
  font-family: Pretendard;

  @media (max-width: 1200px) {
    padding: 8px 16px;
    font-size: 14px;
  }

  &:hover {
    background: ${(props) => (props.isPrimary ? "#357abd" : "#cccccc")};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const AlphaNoteMemo = ({ cuid, code }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newMemo, setNewMemo] = useState("");
  const [editingMemo, setEditingMemo] = useState(null);
  const listRef = useRef(null);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useGetMemos(
    cuid,
    code
  );

  const createMemoMutation = useCreateMemo();
  const updateMemoMutation = useUpdateMemo();
  const deleteMemoMutation = useDeleteMemo();

  const handleScroll = () => {
    if (!listRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = listRef.current;
    if (scrollHeight - scrollTop <= clientHeight * 1.5) {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newMemo.trim()) return;

    if (editingMemo) {
      updateMemoMutation.mutate(
        {
          cuid,
          code,
          memo: newMemo,
          _id: editingMemo._id,
        },
        {
          onSuccess: () => {
            setNewMemo("");
            setEditingMemo(null);
          },
        }
      );
    } else {
      createMemoMutation.mutate(
        {
          cuid,
          code,
          memo: newMemo,
        },
        {
          onSuccess: () => {
            setNewMemo("");
          },
        }
      );
    }
  };

  const handleEdit = (memo) => {
    setEditingMemo(memo);
    setNewMemo(memo.memo);
  };

  const handleCancelEdit = () => {
    setEditingMemo(null);
    setNewMemo("");
  };

  const handleDelete = (memoId) => {
    if (window.confirm("메모를 삭제하시겠습니까?")) {
      deleteMemoMutation.mutate({
        _id: memoId,
        cuid,
        code,
      });
    }
  };

  useEffect(() => {
    if (isOpen && listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [isOpen, data]);

  return (
    <>
      <ToggleButton onClick={() => setIsOpen(!isOpen)} $isOpen={isOpen}>
        {isOpen ? "×" : "메모"}
      </ToggleButton>

      <MemoContainer $isOpen={isOpen}>
        <MemoHeader>
          <div className="title">메모</div>
          <button className="close" onClick={() => setIsOpen(false)}>
            ×
          </button>
        </MemoHeader>

        <MemoList
          ref={listRef}
          onScroll={handleScroll}
          $isEditing={!!editingMemo}
        >
          {data?.pages.map((page, i) => (
            <React.Fragment key={i}>
              {[...page.data.memos].reverse().map((memo) => (
                <MemoItem key={memo._id}>
                  <div className="memo-text">{memo.memo}</div>
                  <div className="memo-time">
                    {new Date(
                      memo.time * 1000 - 9 * 3600 * 1000
                    ).toLocaleString()}
                  </div>
                  <MemoItemActions>
                    <ActionButton
                      onClick={() => handleEdit(memo)}
                      disabled={editingMemo?._id === memo._id}
                    >
                      수정
                    </ActionButton>
                    <ActionButton onClick={() => handleDelete(memo._id)}>
                      삭제
                    </ActionButton>
                  </MemoItemActions>
                </MemoItem>
              ))}
            </React.Fragment>
          ))}
          {isFetchingNextPage && <div>Loading more...</div>}
        </MemoList>

        <InputWrapper onSubmit={handleSubmit}>
          <MemoInput
            value={newMemo}
            onChange={(e) => setNewMemo(e.target.value)}
            placeholder="메모를 입력하세요..."
          />
          <ButtonContainer>
            {editingMemo && (
              <SubmitButton
                type="button"
                onClick={handleCancelEdit}
                style={{ background: "#e0e0e0", color: "#333" }}
              >
                취소
              </SubmitButton>
            )}
            <SubmitButton
              type="submit"
              $fullWidth
              disabled={
                !newMemo.trim() ||
                createMemoMutation.isLoading ||
                updateMemoMutation.isLoading
              }
            >
              {editingMemo ? "수정하기" : "작성하기"}
            </SubmitButton>
          </ButtonContainer>
        </InputWrapper>
      </MemoContainer>
    </>
  );
};

export default AlphaNoteMemo;
