import base64 from "base-64";
import {
  useAlphaMyTraderQuery,
  useAlphaTraderDetailQuery,
} from "hooks/queries/useAlphaTraderDetailQuery";
import LoginRequired from "pages/Login/LoginRequired";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useNotice, useSelectedCompetition } from "utils/store";
import AlphaDetailEmptyNote from "./AlphaDetailEmptyNote";
import AlphaDetailHome from "./AlphaDetailHome";
import AlphaDetailInfo from "./AlphaDetailInfo";
import AlphaDetailMyNote from "./AlphaDetailMyNote";
import AlphaDetailNote from "./AlphaDetailNote";
import TitleSection from "./TitleSection";
import useAuth from "utils/auth";

const HomeWrapper = styled.div`
  width: 100%;
  padding-bottom: 64px 0; # 127px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #f9fafc;

  .section-bg {
    width: 100%;
    background: #16191f;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .fullWidth {
    width: min(
      1128px,
      calc(100% - 32px)
    ); // 1128px와 화면너비-32px 중 작은 값 선택
    box-sizing: border-box;
  }

  .datainfo {
    padding: 12px 0;
  }

  .login-wrapper {
    padding: min(100px, 5vw);
  }
`;

const CuidContext = createContext();

export const useCuid = () => {
  const context = useContext(CuidContext);
  if (!context) {
    throw new Error("useCuid must be used within a CuidProvider");
  }
  return context;
};

export const CuidProvider = ({ children }) => {
  const [cuid, setCuid] = useState(null);

  return (
    <CuidContext.Provider value={{ cuid, setCuid }}>
      {children}
    </CuidContext.Provider>
  );
};

const getHash = (hash) => {
  return hash.replace(/^#/, "").split("?")[0];
};

const TraderDetail = () => {
  const { hash } = window.location;
  const { cuid } = useParams();
  const { pathname } = window.location;

  const { cuid: myCuid, setCuid: setMyCuid } = useCuid();

  const isMyNote = pathname === "/mynote";
  const { isLoggedIn, currentUser } = useAuth();

  const [type, setType] = useState(getHash(hash) || "home");
  const [isHold, setIsHold] = useState(false);
  const { selectedCompetition } = useSelectedCompetition((state) => state);

  const [lastUpdate, setLastUpdate] = useState("");
  const handleLastUpdate = useCallback(
    (date) => {
      setLastUpdate(date);
    },
    [setLastUpdate]
  );

  const {
    data: myPerformanceData,
    isLoading: isMyLoading,
    error: myError,
    refetch: refetchMy,
  } = useAlphaMyTraderQuery({ isMyNote, enabled: isMyNote && isLoggedIn });
  const {
    data: summaryPerformanceData,
    isLoading,
    refetch: refetchSummary,
  } = useAlphaTraderDetailQuery({
    cuid: myCuid,
    isMyNote,
    enabled: myPerformanceData?.current?.cuid,
  });

  useEffect(() => {
    if (isMyNote && isLoggedIn) {
      refetchMy();
    }
  }, [isLoggedIn, isMyNote, refetchMy]);
  useEffect(() => {
    if (myPerformanceData) {
      refetchSummary({ cuid: myCuid });
    }
  }, [myCuid, refetchSummary, myPerformanceData]);

  useEffect(() => {
    refetchSummary();
    if (isMyNote && getHash(hash) === "setting") {
      setType("home");
    }
  }, [myCuid, refetchSummary, isMyNote, hash]);

  useEffect(() => {
    if (cuid) {
      setMyCuid(cuid);
    }
  }, [cuid, setMyCuid]);

  const refetchAllData = () => {
    refetchSummary({ cuid: myCuid });
  };

  useEffect(() => {
    window.location.hash = type;
  }, [type]);

  useEffect(() => {
    if (isMyNote) {
      setMyCuid(myPerformanceData?.current?.cuid);
    }
  }, [myPerformanceData, isMyNote, setMyCuid]);

  useEffect(() => {
    try {
      if (myError?.response?.status === 401) {
        // gotoLogin();
      }
    } catch (e) {
      console.error("Error updating like status:", e.message);
    }
  }, [myError]);

  useEffect(() => {
    setIsHold(hash?.split("?")[1] === "hold=true");

    if (!isMyNote && getHash(hash) === "setting") {
      setType("home");
    } else {
      setType(getHash(hash) || "home");
    }
  }, [hash, isMyNote]);

  if (isMyNote && !isLoggedIn) {
    return (
      <HomeWrapper>
        <div className="login-wrapper">
          <LoginRequired />
        </div>
      </HomeWrapper>
    );
  }

  return (
    <HomeWrapper>
      <div className="section-bg">
        <div className="fullWidth">
          {isMyNote
            ? !isMyLoading && (
                <TitleSection
                  data={myPerformanceData || {}}
                  setTab={setType}
                  selectedTab={type}
                  isMyNote={isMyNote}
                  refetchMy={refetchAllData}
                />
              )
            : !isLoading &&
              summaryPerformanceData && (
                <TitleSection
                  data={summaryPerformanceData || {}}
                  setTab={setType}
                  selectedTab={type}
                  myCuid={myCuid}
                />
              )}
        </div>
      </div>
      <div className="fullWidth">
        {type === "home" &&
          (myCuid ? (
            <AlphaDetailHome cuid={myCuid} setLastUpdate={handleLastUpdate} />
          ) : (
            <AlphaDetailEmptyNote setType={setType} />
          ))}
        {type === "detail" &&
          (myCuid ? (
            <AlphaDetailInfo cuid={myCuid} lastUpdate={lastUpdate} />
          ) : (
            <AlphaDetailEmptyNote setType={setType} />
          ))}
        {type === "notes" &&
          (myCuid ? (
            <AlphaDetailNote
              cuid={myCuid}
              lastUpdate={lastUpdate}
              isHold={isHold}
            />
          ) : (
            <AlphaDetailEmptyNote setType={setType} />
          ))}
        {type === "setting" && <AlphaDetailMyNote cuid={myCuid} />}
      </div>
      {/* <Tooltip id={"detail-tooltip"} place={"bottom"} /> */}
    </HomeWrapper>
  );
};

export default TraderDetail;
